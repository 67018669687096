<template>
  <div class='cyui-layout-wrapper'>

    <cyui-layout>
      <template #header>
        <cyui-header v-if='isCustomHeader'
                     :title='headerTitle'
                     @go-back='goBack'
                     @on-close='handleClosePage'>
          <template #headerRight>
            <div></div>
          </template>
        </cyui-header>
      </template>
      <template #default>
        <div class='cyui-detail-header'>
          <template v-if="`${status}` !== '1'">
            <van-search v-model='roomNo' placeholder='请输入房间号' show-action @search='onSearch'>
              <template #action>
                <div style='color: #75BEFC' @click='onSearch'>搜索</div>
              </template>
            </van-search>
          </template>
          <template v-if="`${status}` === '1' || `${status}` === '4' || `${status}` === '2'">
            <div class='cyui-detail-header-body' style='background-color: #75BEFC;'>
              <div class='cyui-detail-header-status'>
                <div class='cyui-detail-header-status-icon'>
                  <svg-icon icon-class='sign_contract_3'></svg-icon>
                </div>
                <div v-if="`${status}` === '1'">等待管家确认</div>
                <div v-else-if="`${status}` === '2'">等待管家确认续租</div>
                <div v-else-if="`${status}` === '4'">等待客户签署</div>
              </div>
            </div>
          </template>
          <template v-else-if="`${status}` === '5' || `${status}` === '9'">
            <div class='cyui-detail-header-body' style='background-color: #82DDC2;'>
              <div class='cyui-detail-header-status'>
                <div class='cyui-detail-header-status-icon'>
                  <svg-icon icon-class='sign_contract_2'></svg-icon>
                </div>
                <div v-if="`${status}` === '5'">等待客户支付</div>
                <div v-else-if="`${status}` === '9'">签约已完成</div>
              </div>
            </div>
          </template>
          <template
            v-else-if="`${status}` === '3' || `${status}` === '6' || `${status}` === '7' || `${status}` === '8' || `${status}` === '10'">
            <div class='cyui-detail-header-body' style='background-color: #C3C3C3;'>
              <div class='cyui-detail-header-status'>
                <div class='cyui-detail-header-status-icon'>
                  <svg-icon icon-class='sign_contract_1'></svg-icon>
                </div>
                <div v-if="`${status}` === '10'">合同已到期</div>
                <div v-else>签约已取消</div>
              </div>
              <div class='cyui-detail-header-msg'>
                <div>{{ detailInfo.failureMsg || '-' }}</div>
              </div>
            </div>
          </template>
        </div>
        <div class='cyui-section'>
          <div class='cyui-card cyui-section-item'>
            <div class='cyui-card-body' style='padding: 0;'>
              <div class='cyui-section-client'>
                <div class='cyui-list cyui-commodity-info'>
                  <div class='cyui-list-body'>
                    <div class='cyui-list-item cyui-list-multiple'>
                      <div v-if="`${status}` === '1'" class='cyui-list-line'>
                        <div class='cyui-list-thumb'>
                          <img :src='roomInfo.coverImg' alt=''>
                        </div>
                        <div class='cyui-list-content'>
                          <div class='cyui-commodity-title'>{{ roomInfo.projectName || '-' }} {{ roomInfo.roomTypeName
                          || '-' }}
                          </div>
                          <div>{{ roomInfo.area || '-' }}㎡ {{ roomInfo.houseType || '-' }}</div>
                          <div>{{ roomInfo.roomArea || '-' }}·{{ roomInfo.sheet || '-' }}</div>
                          <div class='cyui-commodity-price'><em>{{ roomInfo.monthRentPrice || '-' }}</em> 元/月</div>
                        </div>
                      </div>
                      <div v-else class='cyui-list-title'>
                        <span> {{ detailInfo.baseRoomTypeEnt ? detailInfo.baseRoomTypeEnt.projectName : '' }} </span>
                        <span> {{ detailInfo.contractVO ? '房号：' + detailInfo.contractVO.roomNo : '' }} </span>
                        <span style='color: red;font-size: 14px;' v-if='isShowDay && detailInfo && detailInfo.delegateEndDate'>（托管至{{ dayjs(detailInfo.delegateEndDate).format('YYYY-MM-DD') }}）</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="status === '1'">
            <div class='cyui-card cyui-section-item'>
              <div class='cyui-card-header'>
                <div class='cyui-card-header-line'>
                  <div class='cyui-card-header-content'>
                    签约主体
                  </div>
                </div>
              </div>
              <div class='cyui-card-body' style='padding: 0;'>
                <div class='cyui-section-client'>
                  <div class='cyui-staff-info'>
                    <div class='cyui-staff-info-body'>
                      <div class='cyui-list'>
                        <div class='cyui-list-body'>
                          <div class='cyui-list-item'>
                            <div class='cyui-list-line'>
                              <div class='cyui-list-label cyui-list-label-5'>姓名：</div>
                              <div class='cyui-list-content'>
                                <van-field v-model='confirmParamsInfo.realName'
                                           clearable
                                           input-align='right'
                                           placeholder='请输入姓名'></van-field>
                              </div>
                            </div>
                          </div>
                          <div class='cyui-list-item'>
                            <div class='cyui-list-line'>
                              <div class='cyui-list-label cyui-list-label-5'>手机号：</div>
                              <div class='cyui-list-content'>
                                <van-field v-model='confirmParamsInfo.mobile'
                                           clearable
                                           input-align='right'
                                           placeholder='请输入手机号'
                                           type='tel'
                                           @blur='changeMobile'></van-field>
                              </div>
                            </div>
                          </div>
                          <div class='cyui-list-item cyui-rent-content-item'
                               @click="`${status}` === '1'&&(idCardNoTypeInfo.show = true)">
                            <div class='cyui-list-line'>
                              <div class='cyui-list-content'>
                                <div class='cyui-rent-content-title'>
                                  证件类型：
                                </div>
                              </div>
                              <div class='cyui-list-extra'>
                                <div class='cyui-rent-content-value'>
                                  {{ confirmParamsInfo.idCardNoTypeName }}
                                </div>
                              </div>
                              <div v-if="`${status}` === '1'" class='cyui-list-arrow cyui-list-arrow-right'></div>
                            </div>
                          </div>
                          <div class='cyui-list-item'>
                            <div class='cyui-list-line'>
                              <div class='cyui-list-label cyui-list-label-5'>证件号：</div>
                              <div class='cyui-list-content'>
                                <van-field v-model='confirmParamsInfo.idCard'
                                           clearable
                                           input-align='right'
                                           placeholder='请输入证件号'></van-field>
                              </div>
                            </div>
                          </div>
                          <div class='cyui-list-item'>
                            <div class='cyui-list-line'>
                              <div class='cyui-list-label cyui-list-label-6'>签约人通讯地址：</div>
                              <div class='cyui-list-content'>
                                <van-field v-model='confirmParamsInfo.mailingAddress'
                                           autosize
                                           clearable
                                           input-align='right'
                                           maxlength='100'
                                           placeholder='请输入地址'
                                           rows='1'
                                           type='textarea'></van-field>
                              </div>
                            </div>
                          </div>
                          <div class='cyui-list-item'>
                            <div class='cyui-list-line'>
                              <div class='cyui-list-label cyui-list-label-6'>签约人电子邮箱：</div>
                              <div class='cyui-list-content'>
                                <van-field v-model='confirmParamsInfo.email'
                                           clearable
                                           input-align='right'
                                           placeholder='请输入电子邮箱'></van-field>
                              </div>
                            </div>
                          </div>
                          <div class='cyui-list-item'>
                            <div class='cyui-list-line'>
                              <div class='cyui-list-label cyui-list-label-6'>紧急联系人姓名：</div>
                              <div class='cyui-list-content'>
                                <van-field v-model='confirmParamsInfo.emergencyContactName'
                                           clearable
                                           input-align='right'
                                           placeholder='请输入姓名'></van-field>
                              </div>
                            </div>
                          </div>
                          <div class='cyui-list-item'>
                            <div class='cyui-list-line'>
                              <div class='cyui-list-label cyui-list-label-7'>紧急联系人联系方式：</div>
                              <div class='cyui-list-content'>
                                <van-field v-model='confirmParamsInfo.emergencyContactPhone'
                                           clearable
                                           input-align='right'
                                           placeholder='请输入手机号'
                                           type='tel'></van-field>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="status === '2'">
            <div class='cyui-card cyui-section-item'>
              <div class='cyui-card-header'>
                <div class='cyui-card-header-line'>
                  <div class='cyui-card-header-content'>
                    签约主体
                  </div>
                </div>
              </div>
              <div class='cyui-card-body' style='padding: 0;'>
                <div class='cyui-section-client'>
                  <div class='cyui-staff-info'>
                    <div class='cyui-staff-info-body'>
                      <div class='cyui-list'>
                        <div class='cyui-list-body'>
                          <div class='cyui-list-item'>
                            <div class='cyui-list-line'>
                              <div class='cyui-list-label cyui-list-label-5'>姓名：</div>
                              <div class='cyui-list-content'>{{ detailInfo.realName || '-' }}</div>
                            </div>
                          </div>
                          <div class='cyui-list-item'>
                            <div class='cyui-list-line'>
                              <div class='cyui-list-label cyui-list-label-5'>手机号：</div>
                              <div class='cyui-list-content'>{{ detailInfo.mobile || '-' }}</div>
                            </div>
                          </div>
                          <div v-if='detailInfo.idCardNoType' class='cyui-list-item'>
                            <div class='cyui-list-line'>
                              <div class='cyui-list-label cyui-list-label-5'>证件类型：</div>
                              <div class='cyui-list-content'>
                                {{ detailInfo.idCardNoType | formatIdCardNoType(idCardNoTypeInfo.allListData) }}
                              </div>
                            </div>
                          </div>
                          <div v-else
                               class='cyui-list-item cyui-rent-content-item'
                               @click='idCardNoTypeInfo.show = true'>
                            <div class='cyui-list-line'>
                              <div class='cyui-list-content'>
                                <div class='cyui-rent-content-title'>
                                  证件类型：
                                </div>
                              </div>
                              <div class='cyui-list-extra'>
                                <div class='cyui-rent-content-value'>
                                  {{ confirmParamsInfo.idCardNoTypeName }}
                                </div>
                              </div>
                              <div class='cyui-list-arrow cyui-list-arrow-right'></div>
                            </div>
                          </div>
                          <div class='cyui-list-item'>
                            <div class='cyui-list-line'>
                              <div class='cyui-list-label cyui-list-label-5'>证件号：</div>
                              <div class='cyui-list-content'>{{ detailInfo.idCard || '-' }}</div>
                            </div>
                          </div>
                          <div class='cyui-list-item'>
                            <div class='cyui-list-line'>
                              <div class='cyui-list-label cyui-list-label-6'>签约人通讯地址：</div>
                              <div class='cyui-list-content'>
                                <van-field v-model='confirmParamsInfo.mailingAddress'
                                           autosize
                                           clearable
                                           input-align='right'
                                           maxlength='100'
                                           placeholder='请输入地址'
                                           rows='1'
                                           type='textarea'></van-field>
                              </div>
                            </div>
                          </div>

                          <div class='cyui-list-item'>
                            <div class='cyui-list-line'>
                              <div class='cyui-list-label cyui-list-label-6'>签约人电子邮箱：</div>
                              <div class='cyui-list-content'>
                                <van-field v-model='confirmParamsInfo.email'
                                           clearable
                                           input-align='right'
                                           placeholder='请输入电子邮箱'></van-field>
                              </div>
                            </div>
                          </div>
                          <div class='cyui-list-item'>
                            <div class='cyui-list-line'>
                              <div class='cyui-list-label cyui-list-label-6'>紧急联系人姓名：</div>
                              <div class='cyui-list-content'>
                                <van-field v-model='confirmParamsInfo.emergencyContactName'
                                           clearable
                                           input-align='right'
                                           placeholder='请输入姓名'></van-field>
                              </div>
                            </div>
                          </div>
                          <div class='cyui-list-item'>
                            <div class='cyui-list-line'>
                              <div class='cyui-list-label cyui-list-label-7'>紧急联系人联系方式：</div>
                              <div class='cyui-list-content'>
                                <van-field v-model='confirmParamsInfo.emergencyContactPhone'
                                           clearable
                                           input-align='right'
                                           placeholder='请输入手机号'
                                           type='tel'></van-field>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-if="`${status}` === '1' || `${status}` === '2'">
            <div class='cyui-card cyui-section-item'>
              <div class='cyui-card-header'>
                <div class='cyui-card-header-line'>
                  <div class='cyui-card-header-content'>
                    租赁信息
                  </div>
                </div>
              </div>
              <div class='cyui-card-body' style='padding: 0;'>
                <div class='cyui-section-client'>
                  <div class='cyui-rent'>
                    <div class='cyui-list cyui-rent-content'>
                      <div class='cyui-list-body'>
                        <div v-if="`${status}`==='1'" class='cyui-list-item cyui-rent-content-item'
                             @click='batchInfo.show=true'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                选择批次
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ confirmParamsInfo.batchName }}
                              </div>
                            </div>
                            <div class='cyui-list-arrow cyui-list-arrow-right'></div>
                          </div>
                        </div>
                        <div class='cyui-list-item cyui-rent-content-item'
                             @click="`${status}` === '1'&&(confirmCalendarInfo.show = true)">
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                租赁开始日期
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ confirmParamsInfo.startDate | formatParamsDate }}
                              </div>
                            </div>
                            <div v-if="`${status}` === '1'" class='cyui-list-arrow cyui-list-arrow-right'></div>
                          </div>
                        </div>
                        <div class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                整月数量
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <van-field v-model='confirmParamsInfo.monthNum'
                                         input-align='right'
                                         placeholder='请输入整月数量'
                                         type='digit'
                                         @input='handleInputMonthNumber'>
                                <template #right-icon>
                                  <div>月</div>
                                </template>
                              </van-field>
                            </div>
                          </div>
                        </div>
                        <div class='cyui-list-item cyui-rent-content-item' v-if='isShowDay'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                整天数量
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <van-field v-model='confirmParamsInfo.dayNum'
                                         input-align='right'
                                         placeholder='请输入整天数量'
                                         type='digit'
                                         @input='handleInputDayNumber'>
                                <template #right-icon>
                                  <div>天</div>
                                </template>
                              </van-field>
                            </div>
                          </div>
                        </div>
                        <div class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                租赁结束日期
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ confirmParamsInfo.endDate | formatParamsDate }}
                              </div>
                            </div>
                            <div class='cyui-list-arrow cyui-list-arrow-right'></div>
                          </div>
                        </div>

                        <div v-if="`${status}`==='1'" class='cyui-card cyui-section-item'>
                          <div class='cyui-card-header' style='padding: 0;'>
                            <div class='cyui-card-header-line'>
                              <div class='cyui-card-header-content' style='color: #999999;'>
                                房源选择
                              </div>
                            </div>
                          </div>
                          <div class='cyui-card-body' style='padding: 0;'>
                            <div class='cyui-section-client'>
                              <div v-if='houseListData.length' class='cyui-house-list'>
                                <van-cell-group>
                                  <van-cell v-for='(item) in houseListData'
                                            :key='`house${item.houseCode}`'
                                            @click='handleSelectHouse(item)'>
                                    <template #icon>
                                      <div class='cyui-house-list-thumb'>
                                        <svg-icon :icon-class="item.isSelected ? 'radio_checked' : 'radio'"></svg-icon>
                                      </div>
                                    </template>
                                    <template #title>
                                      <div class='cyui-flexbox cyui-house-item'>
                                        <div class='cyui-flexbox-item'>
                                          <div class='cyui-house-name'>{{ item.projectName }} {{ item.roomNo }}</div>
                                          <div class='cyui-house-name' style='color: red; font-size: 12px;' v-if='isShowDay'>(托管至{{ dayjs(item.delegateEndDate).format('YYYY-MM-DD') }})</div>
                                        </div>
                                      </div>
                                    </template>
                                  </van-cell>
                                </van-cell-group>
                              </div>
                              <div v-else class='no-data'>暂无数据</div>
                              <!--<no-data class="no-data" :msg="noDataMessage" v-else></no-data>-->
                            </div>
                          </div>
                        </div>
                        <div v-if="`${status}`==='1'" class='cyui-list-item cyui-rent-content-item'
                             @click='handleClinchDealSourceInfoShow'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                客户来源
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ confirmParamsInfo.tenantSource }}
                              </div>
                            </div>
                            <div class='cyui-list-arrow cyui-list-arrow-right'></div>
                          </div>
                        </div>
                        <div v-if="`${status}`==='2'" class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                客户来源
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ confirmParamsInfo.tenantSource }}
                              </div>
                            </div>
                            <div class='cyui-list-arrow cyui-list-arrow-right'></div>
                          </div>
                        </div>
                        <div class='cyui-list-item cyui-rent-content-item'
                             @click='handleProfessionInfoShow'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                职业
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ confirmParamsInfo.profession | professionName(that) }}
                              </div>
                            </div>
                            <div class='cyui-list-arrow cyui-list-arrow-right'></div>
                          </div>
                        </div>
                        <div class='cyui-list-item cyui-rent-content-item'
                             @click="`${status}` === '1'&&(expectedDateInfo.show = true)">
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                预计入住日期
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ confirmParamsInfo.expectedDate | formatParamsDate }}
                              </div>
                            </div>
                            <div v-if="`${status}` === '1'" class='cyui-list-arrow cyui-list-arrow-right'></div>
                          </div>
                        </div>
                        <div class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                房屋保证金
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <van-field v-model='confirmParamsInfo.deposit'
                                         :disabled="`${status}`==='2'"
                                         clearable
                                         input-align='right'
                                         placeholder='请输入金额整数'
                                         type='digit'>
                                <template #right-icon>
                                  <div>元</div>
                                </template>
                              </van-field>
                            </div>
                          </div>
                        </div>
                        <div class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                每月综合服务费
                                <div v-if='confirmParamsInfo.monthlyRentMSG'
                                     class='cyui-describe'>
                                  {{ confirmParamsInfo.monthlyRentMSG }}
                                </div>
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <van-field v-model='confirmParamsInfo.monthlyRent'
                                         :disabled='confirmParamsInfo.isSpecial === 1'
                                         clearable
                                         input-align='right'
                                         placeholder='请输入金额整数'
                                         type='digit'
                                         @blur='isMonthlyRentMsg'
                                         @input='handleMonthPriceInput'>
                                <template #right-icon>
                                  <div>元</div>
                                </template>
                              </van-field>
                              <div v-if='amountPtfwf' class='cyui-describe'>(包含配套服务费：{{ amountPtfwf }})</div>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if='amountLjqyf'
                          class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                垃圾清运费
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <van-field :value='amountLjqyf'
                                         clearable
                                         disabled
                                         input-align='right'
                                         style='color: red'
                                         type='digit'>
                                <template #right-icon>
                                  <div>元</div>
                                </template>
                              </van-field>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if='floorPriceInfo.data && floorPriceInfo.data.state === 2'
                          class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                房源底价
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <van-field :value='floorPriceInfo.data.minAmount'
                                         clearable
                                         disabled
                                         input-align='right'
                                         style='color: red'
                                         type='digit'>
                                <template #right-icon>
                                  <div>元</div>
                                </template>
                              </van-field>
                            </div>
                          </div>
                        </div>
                        <div v-if='detailInfo.contractType === 2' class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                上次成交价
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <van-field :value='latestContractPrice'
                                         clearable
                                         disabled
                                         input-align='right'
                                         type='digit'>
                                <template #right-icon>
                                  <div>元</div>
                                </template>
                              </van-field>
                            </div>
                          </div>
                        </div>
                        <div class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                租赁费用总额
                                <div class='cyui-describe'>(综合服务费总额)</div>
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <van-field :value='confirmParamsInfo.totalFee'
                                         clearable
                                         input-align='right'
                                         placeholder='请输入金额整数'
                                         readonly
                                         type='digit'>
                                <template #right-icon>
                                  <div>元</div>
                                </template>
                              </van-field>
                            </div>
                          </div>
                        </div>
                        <div v-if="`${status}`==='1'" class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                入住水表度数
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <van-field v-model='confirmParamsInfo.waterQuantity'
                                         input-align='right'
                                         placeholder='请输入入住水表度数'
                                         type='number'>
                                <template #right-icon>
                                  <div>吨</div>
                                </template>
                              </van-field>
                            </div>
                          </div>
                        </div>
                        <div v-if="`${status}`==='1'" class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                入住电表度数
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <van-field v-model='confirmParamsInfo.electricQuantity'
                                         input-align='right'
                                         placeholder='请输入入住电表度数'
                                         type='number'>
                                <template #right-icon>
                                  <div>度</div>
                                </template>
                              </van-field>
                            </div>
                          </div>
                        </div>
                        <div v-if="`${status}`==='1'" class='cyui-list-item cyui-rent-content-item'
                             @click="`${status}` === '1'&&(waterDateInfo.show = true)">
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                入住水表时间
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ confirmParamsInfo.waterRecordDate | formatParamsDate }}
                              </div>
                            </div>
                            <div v-if="`${status}` === '1'" class='cyui-list-arrow cyui-list-arrow-right'></div>
                          </div>
                        </div>
                        <div v-if="`${status}`==='1'" class='cyui-list-item cyui-rent-content-item'
                             @click="`${status}` === '1'&&(electricDateInfo.show = true)">
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                入住电表时间
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ confirmParamsInfo.electricityRecordDate | formatParamsDate }}
                              </div>
                            </div>
                            <div v-if="`${status}` === '1'" class='cyui-list-arrow cyui-list-arrow-right'></div>
                          </div>
                        </div>
                        <div v-if="`${status}`==='1'" class='cyui-list-item cyui-rent-content-item'>
                          <van-field input-align='right'
                                     name='uploader'>
                            <template #label>
                              <span class='img-title' style='font-size: 12px;color:#999999'>入住水表图片</span>
                            </template>
                            <template #input>
                              <van-uploader v-model='waterFileList'
                                            :after-read='afterWaterIDRead'
                                            :max-count='1'
                                            :max-size='50 * 1024 * 1024'
                                            @delete='deleteWaterImg'
                                            @oversize='onOversize' />
                            </template>
                          </van-field>
                        </div>
                        <div v-if="`${status}`==='1'" class='cyui-list-item cyui-rent-content-item'>
                          <van-field input-align='right'
                                     name='uploader'>
                            <template #label>
                              <span class='img-title' style='font-size: 12px;color:#999999'>入住电表图片</span>
                            </template>
                            <template #input>
                              <van-uploader v-model='electricity'
                                            :after-read='afterElectricityIDRead'
                                            :max-count='1'
                                            :max-size='50 * 1024 * 1024'
                                            @delete='deleteElectricityrImg'
                                            @oversize='onOversize' />
                            </template>
                          </van-field>
                        </div>
                        <div v-if='isPackagePrice === 2' class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                是否为套餐价
                              </div>
                            </div>
                            <div class='cyui-list-extra' style='flex-basis: auto'>
                              <van-radio-group v-model='confirmParamsInfo.packageStatus' direction='horizontal'
                                               icon-size='18px'>
                                <van-radio :name='2'>是</van-radio>
                                <van-radio :name='1'>否</van-radio>
                              </van-radio-group>
                            </div>
                          </div>
                        </div>
                        <div v-if="`${status}`==='1'" class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                是否携带宠物
                              </div>
                            </div>
                            <div class='cyui-list-extra' style='flex-basis: auto'>
                              <van-radio-group v-model='confirmParamsInfo.havePet' direction='horizontal'
                                               icon-size='18px'>
                                <van-radio name='1' @click='handleCarryPet'>是</van-radio>
                                <van-radio name='0'>否</van-radio>
                              </van-radio-group>
                            </div>
                          </div>
                        </div>
                        <div v-if="`${status}`==='2'" class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                是否携带宠物
                              </div>
                            </div>
                            <div class='cyui-list-extra' style='flex-basis: auto'>
                              <van-radio-group :value='confirmParamsInfo.havePet' direction='horizontal'
                                               icon-size='18px'>
                                <van-radio name='1' @click='handleCarryPet'>是</van-radio>
                                <van-radio name='0'>否</van-radio>
                              </van-radio-group>
                            </div>
                          </div>
                        </div>
                        <div class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                是否员工自住房
                              </div>
                            </div>
                            <div class='cyui-list-extra' style='flex-basis: auto'>
                              <van-radio-group v-model='confirmParamsInfo.isSelfRoom' direction='horizontal'
                                               icon-size='18px'>
                                <van-radio name='2'>是</van-radio>
                                <van-radio name='1'>否</van-radio>
                              </van-radio-group>
                            </div>
                          </div>
                        </div>
                        <div v-show="confirmParamsInfo.havePet === '1'" class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                宠物押金
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <van-field v-model='confirmParamsInfo.petDeposit'
                                         :disabled='true'
                                         clearable
                                         input-align='right'
                                         type='digit'>
                                <template #right-icon>
                                  <div>元</div>
                                </template>
                              </van-field>
                            </div>
                          </div>
                        </div>
                        <div class='cyui-list-item cyui-rent-content-item'
                             @click='rentalTypeInfo.show=true'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                租赁类型
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ confirmParamsInfo.modeOfPayment }}
                              </div>
                            </div>
                            <div class='cyui-list-arrow cyui-list-arrow-right'></div>
                          </div>
                        </div>

                        <div v-if='reportData.reportState==1' class='cyui-card cyui-section-item'>
                          <div class='cyui-card-header' style='padding: 0;'>
                            <div class='cyui-card-header-line'>
                              <div class='cyui-card-header-content' style='color: #999999;font-size: 12px'>
                                主要成交参与负责人员
                              </div>
                            </div>
                          </div>
                          <div class='cyui-card-body' style='padding: 0;'>
                            <div class='cyui-section-client'>
                              <div class='cyui-house-list'>
                                <van-cell-group>
                                  <van-cell @click='confirmParamsInfo.beneficiary=2'>
                                    <template #icon>
                                      <div class='cyui-house-list-thumb'>
                                        <svg-icon
                                          :icon-class="confirmParamsInfo.beneficiary==2 ? 'radio_checked' : 'radio'"></svg-icon>
                                      </div>
                                    </template>
                                    <template #title>
                                      <div class='cyui-flexbox cyui-house-item'>
                                        <div class='cyui-flexbox-item'>
                                          <div class='cyui-house-name'>
                                            客户提交报备人-{{ reportData.reportByName }}
                                          </div>
                                        </div>
                                      </div>
                                    </template>
                                  </van-cell>
                                  <van-cell @click='confirmParamsInfo.beneficiary=1'>
                                    <template #icon>
                                      <div class='cyui-house-list-thumb'>
                                        <svg-icon
                                          :icon-class="confirmParamsInfo.beneficiary==1 ? 'radio_checked' : 'radio'"></svg-icon>
                                      </div>
                                    </template>
                                    <template #title>
                                      <div class='cyui-flexbox cyui-house-item'>
                                        <div class='cyui-flexbox-item'>
                                          <div class='cyui-house-name'>客户签约操作人-{{ userInfo.nickName }}</div>
                                        </div>
                                      </div>
                                    </template>
                                  </van-cell>
                                </van-cell-group>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class='cyui-list-item cyui-rent-content-item'
                             @click='stewardsInfo.show=true'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                服务管家
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ confirmParamsInfo.serviceByName }}
                              </div>
                            </div>
                            <div class='cyui-list-arrow cyui-list-arrow-right'></div>
                          </div>
                        </div>

                        <div class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content' style='height: 90%'>
                              <div class='cyui-rent-content-title'>
                                备注
                              </div>
                            </div>
                            <div class='cyui-list-area'>
                              <van-field v-model='confirmParamsInfo.remark'
                                         autosize
                                         maxlength='50'
                                         placeholder='请输入备注'
                                         rows='2'
                                         show-word-limit
                                         type='textarea'
                              />
                            </div>
                          </div>
                        </div>
                        <div v-if='detailInfo.contractType === 1 || detailInfo.contractType === 2'
                             class='cyui-list-item cyui-rent-content-item'
                             @click='handleOpenCouponScheme'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                优惠方案
                                <div v-if='showCouponScheme' class='cyui-describe'>
                                  有符合条件的优惠方案
                                </div>
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ confirmParamsInfo.actCouponProgrammeName }}
                              </div>
                            </div>
                            <div class='cyui-list-arrow cyui-list-arrow-right'></div>
                          </div>
                        </div>
                        <div v-if='detailInfo.contractType === 1 || detailInfo.contractType === 2'
                             class='cyui-list-item cyui-rent-content-item'
                             @click='handleOpenCoupon'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                优惠券
                                <div v-if='couponInfo.listData.length>0' class='cyui-describe'>
                                  有符合条件的优惠券
                                </div>
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ confirmParamsInfo.couponName }}
                              </div>
                            </div>
                            <div class='cyui-list-arrow cyui-list-arrow-right'></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </template>
          <template v-if="`${status}` === '4' || `${status}` === '5' || `${status}` === '9'">
            <div v-if='contractInfo.checkState !== 0' class='cyui-card cyui-section-item'>
              <div class='cyui-card-header'>
                <div class='cyui-card-header-line'>
                  <div class='cyui-card-header-content'>
                    审核信息
                  </div>
                </div>
              </div>
              <div class='cyui-card-body' style='padding: 0;'>
                <div class='cyui-section-client'>
                  <div class='cyui-rent'>
                    <div class='cyui-list cyui-rent-content'>
                      <div class='cyui-list-body'>
                        <div class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                审核状态
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div v-if='contractInfo.checkState === 1' class='cyui-rent-content-value'>
                                审核中
                              </div>
                              <div v-else-if='contractInfo.checkState === 2' class='cyui-rent-content-value'>
                                审核通过
                              </div>
                              <div v-else-if='contractInfo.checkState === 3' class='cyui-rent-content-value'>
                                审核未通过
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                审核类型
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div v-if='contractInfo.checkType === 1' class='cyui-rent-content-value'>
                                新签破底价
                              </div>
                              <div v-if='contractInfo.checkType === 2' class='cyui-rent-content-value'>
                                续签价格过低
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                提交审核时间
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ contractInfo.checkSubmitTime }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if='contractInfo.checkType === 1' class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                审核底价价格
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ contractInfo.checkPriceMin }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if='contractInfo.checkType === 2' class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                审核续签价格
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ contractInfo.checkPriceRenew }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class='cyui-card cyui-section-item'>
              <div class='cyui-card-header'>
                <div class='cyui-card-header-line'>
                  <div class='cyui-card-header-content'>
                    租赁信息
                  </div>
                </div>
              </div>
              <div class='cyui-card-body' style='padding: 0;'>
                <div class='cyui-section-client'>
                  <div class='cyui-rent'>
                    <div class='cyui-list cyui-rent-content'>
                      <div class='cyui-list-body'>
                        <div class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                租赁开始日期
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ contractInfo.startDate | formatParamsDate }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                租赁结束日期
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ contractInfo.endDate | formatParamsDate }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                预计入住日期
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ contractInfo.expectedDate | formatParamsDate }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                客户来源
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ contractInfo.tenantSource }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                房屋保证金
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ contractInfo.deposit | formatConfirmParamsMoney }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                每月综合服务费
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ contractInfo.monthlyRent | formatConfirmParamsMoney }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                租赁费用总额
                                <div class='cyui-describe'>(综合服务费总额)</div>
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ contractInfo.totalFee | formatConfirmParamsMoney }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                入住水表度数
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ contractInfo.waterQuantity }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                入住电表度数
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ contractInfo.electricQuantity }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                入住水表时间
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ contractInfo.waterRecordDate | formatParamsDate }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                入住电表时间
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ contractInfo.electricityRecordDate | formatParamsDate }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                入住水表图片
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <van-image
                                :src='contractInfo.checkInWaterPicture'
                                height='80'
                                lazy-load
                                width='80'
                                @click='reviewImg(contractInfo.checkInWaterPicture)'
                              />
                            </div>
                          </div>
                        </div>
                        <div class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                入住电表图片
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <van-image
                                :src='contractInfo.checkInElectricityPicture'
                                height='80'
                                lazy-load
                                width='80'
                                @click='reviewImg(contractInfo.checkInElectricityPicture)'
                              />
                            </div>
                          </div>
                        </div>
                        <div v-if='isPackagePrice === 2' class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                是否为套餐价
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ contractInfo.packageStatus === 1 ? '关闭' : '开启' }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-show='contractInfo.petDeposit !== 0' class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                宠物押金
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ contractInfo.petDeposit | formatConfirmParamsMoney }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                租赁类型
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ contractInfo.modeOfPayment }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                备注
                              </div>
                            </div>
                            <div class='cyui-list-extra'>
                              <div class='cyui-rent-content-value'>
                                {{ contractInfo.remark }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class='cyui-card cyui-section-item'>
              <div class='cyui-card-header'>
                <div class='cyui-card-header-line'>
                  <div class='cyui-card-header-content'>
                    房源选择
                  </div>
                </div>
              </div>
              <div class='cyui-card-body' style='padding: 0;'>
                <div class='cyui-section-client'>
                  <div class='cyui-rent'>
                    <div class='cyui-list cyui-rent-content'>
                      <div class='cyui-list-body'>
                        <div class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                {{ contractInfo.projectName || '-' }} {{ contractInfo.roomNo || '-' }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class='cyui-card cyui-section-item'>
            <div class='cyui-card-body' style='padding: 0;'>
              <div class='cyui-section-client'>
                <div class='cyui-staff-info'>
                  <div class='cyui-staff-info-body'>
                    <div class='cyui-list'>
                      <div class='cyui-list-body'>
                        <!--                        <div v-if="`${status}` === '1' || `${status}` === '2'"-->
                        <!--                             class='cyui-list-item'>-->
                        <!--                          <div class='cyui-list-line'>-->
                        <!--                            <div class='cyui-list-label cyui-list-label-5'>申请时间：</div>-->
                        <!--                            <div class='cyui-list-content'>{{ detailInfo.createTime || '-' }}</div>-->
                        <!--                          </div>-->
                        <!--                        </div>-->
                        <div v-if="`${status}` === '1' || `${status}` === '2'"
                             class='cyui-list-item cyui-rent-content-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-content'>
                              <div class='cyui-rent-content-title'>
                                是否有同住人
                              </div>
                            </div>
                            <div class='cyui-list-extra' style='flex-basis: auto'>
                              <van-radio-group v-model='confirmParamsInfo.ifCompanionTenant'
                                               direction='horizontal'
                                               icon-size='18px'
                                               @change='handleRoommateChange'>
                                <van-radio :name='1'>是</van-radio>
                                <van-radio :name='0'>否</van-radio>
                              </van-radio-group>
                            </div>
                          </div>
                        </div>
                        <div v-if="`${status}` === '4' || `${status}` === '5' || `${status}` === '9'"
                             class='cyui-list-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-label cyui-list-label-5'>管家确认：</div>
                            <div class='cyui-list-content'>{{ detailInfo.passTime || '-' }}</div>
                          </div>
                        </div>
                        <!--已完成-->
                        <div v-if="`${status}` === '5' || `${status}` === '9'" class='cyui-list-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-label cyui-list-label-5'>签约完成：</div>
                            <div class='cyui-list-content'>{{ detailInfo.successTime || '-' }}</div>
                          </div>
                        </div>
                        <div v-if="`${status}` === '9'"
                             class='cyui-list-item'>
                          <div class='cyui-list-line'>
                            <div class='cyui-list-label cyui-list-label-5'>支付时间：</div>
                            <div class='cyui-list-content'>{{ detailInfo.payPaidTime || '-' }}</div>
                          </div>
                        </div>
                        <!--已失效-->
                        <template
                          v-if="`${status}` === '3' || `${status}` === '6' || `${status}` === '7' || `${status}` === '8' || `${status}` === '10'">
                          <div class='cyui-list-item'>
                            <div class='cyui-list-line'>
                              <div class='cyui-list-label cyui-list-label-5'>
                                {{ `${status}` === '10' ? '失效时间：' : '取消时间：' }}
                              </div>
                              <div class='cyui-list-content'>{{ detailInfo.failureTime || '-' }}</div>
                            </div>
                          </div>
                          <!--<div class="cyui-list-item">
                            <div class="cyui-list-line">
                              <div class="cyui-list-label cyui-list-label-5">
                                  {{ `${status}` === '10' ? '失效原因：': '取消原因：' }}
                              </div>
                              <div class="cyui-list-content">{{ detailInfo.failureMsg || '-' }}</div>
                            </div>
                          </div>-->
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if='confirmParamsInfo.ifCompanionTenant == 1'>
            <div v-for='(item, index) in confirmParamsInfo.companionDetailSubList' :key='index'
                 class='cyui-card cyui-section-item'
                 style='margin: 10px 0;'>
              <div class='cyui-card-header'>
                <div class='cyui-card-header-line'>
                  <div class='cyui-card-header-content'>
                    同住人信息-{{ index + 1 }}
                  </div>
                  <div class='cyui-flexbox'>
                    <van-icon name='cross' size='20px' @click='handleRoommateDel(index)' />
                  </div>
                </div>
              </div>
              <div class='cyui-card-body' style='padding: 0;'>
                <div class='cyui-section-client'>
                  <div class='cyui-staff-info'>
                    <div class='cyui-staff-info-body'>
                      <div class='cyui-list'>
                        <div class='cyui-list-body'>
                          <div class='cyui-list-item'>
                            <div class='cyui-list-line'>
                              <div class='cyui-list-label cyui-list-label-6'>同住人姓名：</div>
                              <div class='cyui-list-content'>
                                <van-field v-model='item.companionName'
                                           clearable
                                           input-align='right'
                                           placeholder='请输入'></van-field>
                              </div>
                            </div>
                          </div>
                          <div class='cyui-list-item'>
                            <div class='cyui-list-line'>
                              <div class='cyui-list-label cyui-list-label-6'>同住人身份证号：</div>
                              <div class='cyui-list-content'>
                                <van-field v-model='item.companionIdCardNo'
                                           clearable
                                           input-align='right'
                                           placeholder='请输入'></van-field>
                              </div>
                            </div>
                          </div>
                          <div class='cyui-list-item'>
                            <div class='cyui-list-line'>
                              <div class='cyui-list-label cyui-list-label-6'>同住人手机号：</div>
                              <div class='cyui-list-content'>
                                <van-field v-model='item.companionPhone'
                                           clearable
                                           input-align='right'
                                           placeholder='请输入'
                                           type='tel'></van-field>
                              </div>
                            </div>
                          </div>
                          <div class='cyui-list-item'>
                            <div class='cyui-list-line'>
                              <div class='cyui-list-label cyui-list-label-6'>同住人关系：</div>
                              <div class='cyui-list-content'>
                                <van-field v-model='item.companionRelation'
                                           clearable
                                           input-align='right'
                                           placeholder='请输入'></van-field>
                              </div>
                            </div>
                          </div>
                          <div class='cyui-list-item'>
                            <div class='cyui-list-line'>
                              <div class='cyui-list-label cyui-list-label-6'>同住人年龄：</div>
                              <div class='cyui-list-content'>
                                <van-field v-model='item.companionAge'
                                           clearable
                                           input-align='right'
                                           placeholder='请输入'></van-field>
                              </div>
                            </div>
                          </div>
                          <div class='cyui-list-item'>
                            <div class='cyui-list-line'>
                              <div class='cyui-list-content'>
                                <div class='cyui-rent-content-title'>
                                  同住人性别：
                                </div>
                              </div>
                              <div class='cyui-list-extra' style='flex-basis: auto'>
                                <van-radio-group v-model='item.companionSex' direction='horizontal'
                                                 icon-size='18px'>
                                  <van-radio name='男'>男</van-radio>
                                  <van-radio name='女'>女</van-radio>
                                </van-radio-group>
                              </div>
                            </div>
                          </div>
                          <div class='cyui-list-item'>
                            <div class='cyui-list-line'>
                              <div class='cyui-list-label cyui-list-label-6'>同住人籍贯：</div>
                              <div class='cyui-list-content'>
                                <van-field v-model='item.companionNative'
                                           clearable
                                           input-align='right'
                                           placeholder='请输入'></van-field>
                              </div>
                            </div>
                          </div>
                          <div class='cyui-list-item'>
                            <div class='cyui-list-line'>
                              <div class='cyui-list-label cyui-list-label-6'>同住人家庭地址：</div>
                              <div class='cyui-list-content'>
                                <van-field v-model='item.companionHomeAddress'
                                           autosize
                                           clearable
                                           input-align='right'
                                           maxlength='100'
                                           placeholder='请输入'
                                           rows='1'
                                           type='textarea'></van-field>
                              </div>
                            </div>
                          </div>
                          <div class='cyui-list-item'>
                            <div class='cyui-list-line'>
                              <div class='cyui-list-label cyui-list-label-6'>同住人紧急联系人：</div>
                              <div class='cyui-list-content'>
                                <van-field v-model='item.companionEmergencyContactName'
                                           clearable
                                           input-align='right'
                                           placeholder='请输入'></van-field>
                              </div>
                            </div>
                          </div>
                          <div class='cyui-list-item'>
                            <div class='cyui-list-line'>
                              <div class='cyui-list-label cyui-list-label-8'>同住人紧急联系人手机号：</div>
                              <div class='cyui-list-content'>
                                <van-field v-model='item.companionEmergencyContactPhone'
                                           clearable
                                           input-align='right'
                                           placeholder='请输入'
                                           type='tel'></van-field>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class='cyui-actionbar'>
              <div class='cyui-actionbar-item cyui-actionbar-btns'>
                <div
                  class='cyui-btn cyui-btn-ghost cyui-btn-block'
                  @click='handleRoommateAdd'
                >
                  <van-icon name='plus' size='12px' />
                  添加同住人
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class='cyui-sign-contract-btns'>
          <template v-if="`${status}` === '1' || `${status}` === '2'">
            <div
              class='cyui-btn cyui-btn-ghost cyui-btn-block'
              @click='handleConfirmSignContract'
            >
              确认信息
            </div>
            <!--            <div-->
            <!--              class='cyui-btn cyui-btn-ghost cyui-btn-block'-->
            <!--              @click='handleCancelSignContract'-->
            <!--            >-->
            <!--              取消签约-->
            <!--            </div>-->
          </template>
          <template v-else-if="`${status}` === '4'">
            <a
              :href="`tel:${detailInfo.mobile || '#'}`"
              class='cyui-btn cyui-btn-primary cyui-btn-block'
            >
              联系客户
            </a>
            <div
              class='cyui-btn cyui-btn-ghost cyui-btn-block'
              @click='handleCancelSignContract'
            >
              取消签约
            </div>
          </template>
          <template v-else-if="`${status}` === '5' || `${status}` === '9'">
            <div
              class='cyui-btn cyui-btn-primary cyui-btn-block'
              @click='handleViewContract'
            >
              查看合同
            </div>
          </template>
        </div>
      </template>
      <template #footer>
      </template>
    </cyui-layout>
    <van-popup v-model='idCardNoTypeInfo.show'
               :get-container='getContainer'
               position='bottom'>
      <van-picker
        :columns='idCardNoTypeInfo.listData'
        :title='idCardNoTypeInfo.title'
        item-height='1.22667rem'
        show-toolbar
        @cancel='idCardNoTypeInfo.show = false'
        @confirm='handleIdCardNoTypeConfirm'
      />
    </van-popup>
    <van-popup v-model='houseTypeInfo.show'
               :get-container='getContainer'
               position='bottom'>
      <van-picker
        :columns='houseTypeInfo.listData'
        :title='houseTypeInfo.title'
        item-height='1.22667rem'
        show-toolbar
        @cancel='handleHouseTypeCancel'
        @confirm='handleHouseTypeConfirm'
      />
    </van-popup>
    <van-calendar ref='rentalDateCalendar'
                  @open='openCalendar'
                  v-model='confirmCalendarInfo.show'
                  :default-date='confirmCalendarInfo.defaultDate'
                  :get-container='getContainer'
                  :max-date='confirmCalendarInfo.maxDate'
                  :min-date='confirmCalendarInfo.minDate'
                  color='#D1A36F'
                  type='single'
                  @confirm='handleSelectCalendar' />
    <van-calendar ref='expectedDateCalendar'
                  v-model='expectedDateInfo.show'
                  :default-date='expectedDateInfo.defaultDate'
                  :get-container='getContainer'
                  :max-date='expectedDateInfo.maxDate'
                  :min-date='expectedDateInfo.minDate'
                  color='#D1A36F'
                  type='single'
                  @confirm='handleSelectExpectedDate' />
    <van-calendar ref='electricDateCalendar'
                  v-model='electricDateInfo.show'
                  :default-date='electricDateInfo.defaultDate'
                  :get-container='getContainer'
                  :max-date='electricDateInfo.maxDate'
                  :min-date='electricDateInfo.minDate'
                  color='#D1A36F'
                  type='single'
                  @confirm='handleSelectElectricDateInfoDate' />
    <van-calendar ref='waterDateCalendar'
                  v-model='waterDateInfo.show'
                  :default-date='waterDateInfo.defaultDate'
                  :get-container='getContainer'
                  :max-date='waterDateInfo.maxDate'
                  :min-date='waterDateInfo.minDate'
                  color='#D1A36F'
                  type='single'
                  @confirm='handleSelectWaterDateInfoDate' />
    <van-popup v-model='batchInfo.show'
               :get-container='getContainer'
               position='bottom'>
      <van-picker
        :columns='batchInfo.listData'
        :title='batchInfo.title'
        item-height='1.22667rem'
        value-key='batchName'
        show-toolbar
        @cancel='batchInfo.show = false'
        @confirm='handleBatch'
      />
    </van-popup>
    <van-popup v-model='clinchDealSourceInfo.show'
               :get-container='getContainer'
               position='bottom'>
      <van-picker
        :columns='clinchDealSourceInfo.listData'
        :title='clinchDealSourceInfo.title'
        item-height='1.22667rem'
        show-toolbar
        @cancel='clinchDealSourceInfo.show = false'
        @confirm='handleRentalSourceType'
      />
    </van-popup>
    <van-popup v-model='professionInfo.show'
               :get-container='getContainer'
               position='bottom'>
      <van-search
        v-model='professionInfo.search'
        placeholder='请输入职业'
        @input='handleProfessionSearch'
      />
      <van-picker
        :columns='professionInfo.listData'
        :title='professionInfo.title'
        item-height='1.22667rem'
        show-toolbar
        @cancel='professionInfo.show = false'
        @confirm='handleProfessionInfo'
      />
    </van-popup>
    <van-popup v-model='rentalTypeInfo.show'
               :get-container='getContainer'
               position='bottom'>
      <van-picker
        :columns='rentalTypeInfo.listData'
        :title='rentalTypeInfo.title'
        item-height='1.22667rem'
        show-toolbar
        @cancel='rentalTypeInfo.show = false'
        @confirm='handleSelectRentalType'
      />
    </van-popup>
    <van-dialog v-model='floorPriceInfo.show'
                :before-close='floorPriceBeforeClose'
                confirm-button-color='#fff'
                show-cancel-button>
      <template #title>
        <p>提示</p>
        <p>您填写的每月综合服务费已经低于规定的定价/底价，请再次确认并阐述原因。</p>
      </template>
      <van-form>
        <van-field v-model='confirmParamsInfo.amountRemark'
                   autofocus
                   autosize
                   class='my-textarea'
                   label='原因'
                   label-width='30'
                   maxlength='200'
                   placeholder='原因必填'
                   required
                   rows='3'
                   show-word-limit
                   type='textarea' />
      </van-form>
    </van-dialog>
    <van-popup v-model='couponSchemeInfo.show'
               :get-container='getContainer'
               position='bottom'>
      <van-picker
        :columns='couponSchemeInfo.listData'
        :title='couponSchemeInfo.title'
        item-height='1.22667rem'
        show-toolbar
        value-key='name'
        @cancel='couponSchemeInfo.show = false'
        @confirm='handleSelectCouponScheme'
      />
    </van-popup>
    <van-popup v-model='couponInfo.show'
               :get-container='getContainer'
               position='bottom'>
      <van-picker
        :columns='couponInfo.listData'
        :title='couponInfo.title'
        item-height='1.22667rem'
        show-toolbar
        value-key='couponName'
        @cancel='couponInfo.show = false'
        @confirm='handleSelectCoupon'
      />
    </van-popup>
    <van-popup v-model='stewardsInfo.show'
               :get-container='getContainer'
               position='bottom'>
      <van-picker
        :columns='stewardsInfo.listData'
        :title='stewardsInfo.title'
        item-height='1.22667rem'
        show-toolbar
        value-key='realname'
        @cancel='stewardsInfo.show = false'
        @confirm='handleSelectSteward'
      />
    </van-popup>
    <van-dialog v-model='copyInfo.show'
                :title='copyInfo.title'
                show-cancel-button
                cancel-button-text='关闭'
                confirm-button-text='复制'
                confirm-button-color='#fff'
                @cancel='handleCopyCancel'
                @confirm='handleCopyConfirm'
                :before-close='copyBeforeClose'>
      <div class='copy-text'>{{ copyInfo.value }}</div>
    </van-dialog>
  </div>
</template>

<script>
  import { evaluate } from 'mathjs'
  import { mapGetters } from 'vuex'
  import { debounce } from 'lodash'
  import dayjs from 'dayjs'
  import common from '@mixins/common'
  import signContractMixins from './mixins/signContract'
  import filters from '@filters/filters'
  import {
    confirmSignContractAPI,
    getAllowedSigningDays,
    getCheckState,
    getClinchDealSouceAPI,
    getCouponList,
    getCouponSchemeList,
    getDetailAPI,
    getFloorPriceAPI,
    getHouseListAPI,
    getHousePriceByRentId,
    getLastDateAPI,
    getPackagePriceAPI,
    getPetDepositAPI,
    renewalConfirmSignContractAPI,
    confirmSignContractOfflineAPI,
    setCheck,
    getHouseTypeListApi,
    getRoomIdAPI,
    getStewardsListByProject,
    getCustomerReportUser, getProfessionAPI, getPtfwf,
    getIsInBlacklistApi, getBatchList, getProjectAllowDayApi, getMonthPrice
  } from '@api/signContract'
  import { fileUpload } from '@api/maintenance'
  import { Dialog, ImagePreview } from 'vant'

  export default {
    name: 'SignContractDetail',
    mixins: [common, signContractMixins, filters],
    components: {},
    data() {
      return {
        that: this,
        roomNo: '',
        headerTitle: '签约详情',
        isShowDay: false,
        confirmParamsInfo: { // 确认信息参数
          contractType: '', // 签约类型
          realName: '', // 姓名
          mobile: '', // 手机号
          idCardNoTypeName: '', // 证件类型名称
          idCard: '', // 证件号
          mailingAddress: '', // 签约人通讯地址
          email: '', // 签约人电子邮箱
          emergencyContactName: '', // 紧急联系人姓名
          emergencyContactPhone: '', // 紧急联系人联系方式
          idCardNoType: '', // 证件类型
          contractId: '', // 合同ID
          roomTypeName: '', // 房型名称
          startDate: '', // 租赁开始日期
          monthNum: '', // 整月数量
          dayNum: '', // 整天数量
          endDate: '', // 租赁结束日期
          isSpecial: '', // 是否特价房
          tenantSource: '', // 客户来源
          expectedDate: '', // 预计入住日期
          monthlyRent: '', // 每月租金
          monthlyRentMSG: '', // 每月综合服务费提示信息
          totalFee: 0, // 租赁费用总额
          electricQuantity: '', // 入住电表度数
          waterQuantity: '', // 入住水表度数
          electricityRecordDate: '', // 入住电表时间
          waterRecordDate: '', // 入住水表时间
          checkInWaterPicture: '', // 入住水表图片
          checkInElectricityPicture: '', // 入住电表图片
          packageStatus: 1, // 套餐价
          deposit: '', // 押金
          space: '', // 面积
          roomNo: '', // 房间号
          projectName: '', // 项目名
          owner: '', // 户主
          houseCode: '',
          havePet: '1',
          isSelfRoom: '1',
          petDeposit: '',
          modeOfPayment: '', // 支付方式（月付，季付，半年付，年付）
          amountRemark: '',
          remark: '', // 备注
          actCouponProgrammeId: '',
          actCouponProgrammeName: '',
          couponId: '',
          couponName: '',
          profession: '',
          ifCompanionTenant: 0, // 同住人
          beneficiary: '', // 主要成交参与负责人员
          companionDetailSubList: [ // 同住人列表
            // {
            //   companionName: '', // 姓名
            //   companionIdCardNo: '', // 身份证号码
            //   companionPhone: '', // 手机号码
            //   companionRelation: '', // 关系
            //   companionAge: '', // 年龄
            //   companionSex: '男', // 性别
            //   companionNative: '', // 籍贯
            //   companionHomeAddress: '', // 家庭地址
            //   companionEmergencyContactName: '', // 同住人紧急联系人姓名
            //   companionEmergencyContactPhone: '' // 同住人紧急联系人电话
            // }
          ],
          batchId: '',
          batchName: ''
        },
        waterFileList: [],
        electricity: [],
        isInBlackInfo: {
          show: false,
          des: '当前客户为受限客户，无法进行签约'
        },
        idCardNoTypeInfo: { // 身份郑类型
          show: false,
          title: '请选择证件类型',
          listData: ['身份证', '护照', '香港居民来往内地通行证', '台胞证'],
          allListData: [
            {
              label: '身份证',
              value: '0'
            },
            {
              label: '护照',
              value: '1'
            },
            {
              label: '香港居民来往内地通行证',
              value: 'B'
            },
            {
              label: '台胞证',
              value: 'C'
            }
          ]
        },
        stewardsInfo: {
          show: false,
          title: '请选择管家',
          listData: []
        },
        reportData: {},
        houseTypeInfo: { // 户型
          show: false,
          title: '请选择户型',
          listData: [],
          allListData: []
        },
        confirmCalendarInfo: { // 确认信息日期选择
          show: false,
          date: [],
          minDate: new Date('2020/01/01'),
          defaultDate: new Date()
        },
        batchInfo: {
          show: false,
          title: '请选择批次',
          listData: []
        },
        clinchDealSourceInfo: {
          show: false,
          title: '请选择客户来源',
          listData: []
        },
        professionInfo: {
          show: false,
          title: '请选择职业',
          search: '',
          listData: [],
          allListData: []
        },
        expectedDateInfo: { // 预计入住日期选择
          show: false,
          date: '',
          minDate: new Date(),
          maxDate: new Date(dayjs().add(6, 'year')),
          defaultDate: new Date()
        },
        electricDateInfo: { // 入住时电表抄表日期
          show: false,
          date: '',
          minDate: new Date(dayjs().add(-6, 'year')),
          maxDate: new Date(dayjs().add(6, 'year')),
          defaultDate: new Date()
        },
        waterDateInfo: { // 入住时水表抄表日期
          show: false,
          date: '',
          minDate: new Date(dayjs().add(-6, 'year')),
          maxDate: new Date(dayjs().add(6, 'year')),
          defaultDate: new Date()
        },
        rentalTypeInfo: { // 租赁类型modeOfPayment
          show: false,
          title: '请选择租赁类型',
          listData: ['月付', '季付', '半年付', '年付', '全额支付']
        },
        couponSchemeInfo: { // 优惠方案
          show: false,
          title: '请选择优惠方案',
          listData: []
        },
        couponInfo: {
          show: false,
          title: '请选择优惠券',
          listData: []
        },
        floorPriceInfo: {
          show: false,
          data: null,
          value: ''
        }, // 房源底价
        copyInfo: { // 复制
          show: false,
          title: '合同签署链接:',
          value: ''
        },
        latestContractPrice: 0, // 上次成交价
        latestContractMonths: '', // 上次成交价
        setTenantSource: true, // 控制客户来源是否可点击
        isLoading: false, // 是否在加载数据
        isRefreshPage: false, // 是否需要刷新页面
        isPackagePrice: 1, // 是否套餐价
        noDataMessage: '暂无数据～', // 列表为空时的提示文案
        detailInfo: {
          status: '',
          baseRoomTypeEnt: {}
        }, // 详情信息
        houseListData: [], // 房源列表数据,
        amountLjqyf: '', // 垃圾清运费
        amountPtfwf: ''// 配套服务费
      }
    },
    props: {
      contractId: {
        type: [Number, String],
        default: ''
      }
    },
    computed: {
      ...mapGetters('user', [
        'projectInfo', 'userInfo'
      ]),
      status: {
        deep: true,
        get: function() { // 当前状态
          let { detailInfo } = this
          console.log(detailInfo, 'detailInfo')
          return detailInfo.status
        }
      },
      roomInfo() { // 户型信息
        let { detailInfo } = this
        return detailInfo.baseRoomTypeEnt || {}
      },
      contractInfo() { // 合同信息
        let { detailInfo } = this
        // petDeposit
        detailInfo.contractVO.petDeposit = detailInfo.petDeposit
        // tenantSource
        detailInfo.contractVO.tenantSource = detailInfo.tenantSource
        // packageStatus
        detailInfo.contractVO.packageStatus = detailInfo.packageStatus
        detailInfo.contractVO.electricQuantity = detailInfo.electricQuantity
        detailInfo.contractVO.waterQuantity = detailInfo.waterQuantity
        detailInfo.contractVO.electricityRecordDate = detailInfo.electricityRecordDate
        detailInfo.contractVO.waterRecordDate = detailInfo.waterRecordDate
        detailInfo.contractVO.checkInWaterPicture = detailInfo.checkInWaterPicture
        detailInfo.contractVO.checkInElectricityPicture = detailInfo.checkInElectricityPicture
        detailInfo.contractVO.remark = detailInfo.remark
        detailInfo.contractVO.checkState = detailInfo.checkState
        detailInfo.contractVO.checkType = detailInfo.checkType || ''
        detailInfo.contractVO.checkSubmitTime = detailInfo.checkSubmitTime || ''
        detailInfo.contractVO.checkPriceMin = detailInfo.checkPriceMin || ''
        detailInfo.contractVO.checkPriceRenew = detailInfo.checkPriceRenew || ''
        return detailInfo.contractVO || {}
      },
      totalFee() { // 租金总额
        let {
          monthlyRent,
          // serviceFee,
          monthNum
        } = this.confirmParamsInfo
        if (monthlyRent !== '' && monthNum !== '') {
          if (this.floorPriceInfo.data && this.floorPriceInfo.data.minAmount && !this.floorPriceInfo.data.canBreakMinAmount) {
            // contractType
            if (this.latestContractMonths !== this.confirmParamsInfo.monthNum) {
              if (Number(monthlyRent) < this.floorPriceInfo.data.minAmount) {
                return 0
              } else {
                return evaluate(`${monthlyRent} * ${monthNum}`)
              }
            } else {
              if (Number(monthlyRent) < this.latestContractPrice) {
                return 0
              } else {
                return evaluate(`${monthlyRent} * ${monthNum}`)
              }
            }
          } else {
            return evaluate(`${monthlyRent} * ${monthNum}`)
          }
        } else {
          return 0
        }
      },
      showCouponScheme() {
        return this.couponSchemeInfo.listData?.length > 0 && this.confirmParamsInfo.monthlyRent && this.confirmParamsInfo.monthNum
      }
    },
    methods: {
      dayjs,
      // 返回一个特定的 DOM 节点，作为挂载的父节点
      getContainer() {
        return document.querySelector('body')
      },
      changeMobile(e) {
        this.getReportData()
        this.getIsInBlacklist()
      },
      // 初始化房源选择信息
      async initHouseParams() {
        // this.confirmParamsInfo.space = ''
        // this.confirmParamsInfo.roomNo = ''
        this.confirmParamsInfo.projectName = ''
        this.confirmParamsInfo.owner = ''
        // this.confirmParamsInfo.houseCode = ''
        this.confirmParamsInfo.havePet = '0'
        this.confirmParamsInfo.petDeposit = ''
      },
      // 初始化租赁开始结束时间
      async initRentalDateParams() {
        // this.$refs.rentalDateCalendar.reset()
        // this.confirmParamsInfo.startDate = ''
        this.confirmParamsInfo.monthNum = ''
        this.confirmParamsInfo.endDate = ''
      },
      // 初始化预计入住时间
      async initExpectedDateParams() {
        this.$refs.expectedDateCalendar.reset()
        this.confirmParamsInfo.expectedDate = ''
      },
      // 获取是否有提交报备人
      getReportData() {
        let { dcProjectId } = this.projectInfo
        getCustomerReportUser({
          projectId: dcProjectId,
          phone: this.confirmParamsInfo.mobile
        }).then(res => {
          if (res.data.code === '10000') {
            this.reportData = res.data.data
          }
        })
      },
      getIsInBlacklist() {
        let param = {
          phone: this.confirmParamsInfo.mobile
        }
        getIsInBlacklistApi(param).then((res) => {
          if (res.data.code === '10000') {
            this.isInBlackInfo.show = res.data.data.isInBlacklist === 1
          }
          if (this.isInBlackInfo.show) {
            this.$notify({
              type: 'warning',
              message: this.isInBlackInfo.des
            })
          }
        })
      },
      // 验证预计入住日期是否有效
      async validateExpectedDate() {
        let {
          startDate,
          endDate,
          expectedDate
        } = this.confirmParamsInfo
        let resultData = {
          message: '有效的',
          effective: true
        }
        if (dayjs(startDate).unix() > dayjs(expectedDate).unix() || dayjs(endDate).unix() < dayjs(expectedDate).unix()) {
          resultData.message = '请选择预计入住日期，在租赁日期范围内！'
          resultData.effective = false
        }
        return resultData
      },
      // 根据租赁类型验证开始时间和结束时间
      async validateStartEndDate() {
        // '月付', '二月付', '季付', '半年付', '年付', '全额支付'
        let {
          startDate,
          endDate,
          modeOfPayment
        } = this.confirmParamsInfo
        let resultData = {
          message: '有效的',
          effective: true
        }
        switch (modeOfPayment) {
          case '月付':
            if (dayjs(startDate).add('1', 'M').unix() > dayjs(endDate).unix()) {
              resultData.message = '租赁类型为月付，开始时间到结束时间至少一个月！'
              resultData.effective = false
            }
            break
          case '二月付':
            if (dayjs(startDate).add('2', 'M').unix() > dayjs(endDate).unix()) {
              resultData.message = '租赁类型为二月付，开始时间到结束时间至少两个月！'
              resultData.effective = false
            }
            break
          case '季付':
            if (dayjs(startDate).add('3', 'M').unix() > dayjs(endDate).unix()) {
              resultData.message = '租赁类型为季付，开始时间到结束时间至少三个月！'
              resultData.effective = false
            }
            break
          case '半年付':
            if (dayjs(startDate).add('6', 'M').unix() > dayjs(endDate).unix()) {
              resultData.message = '租赁类型为半年付，开始时间到结束时间至少半年！'
              resultData.effective = false
            }
            break
          case '年付':
            if (dayjs(startDate).add('1', 'y').unix() > dayjs(endDate).unix()) {
              resultData.message = '租赁类型为年付，开始时间到结束时间至少一年！'
              resultData.effective = false
            }
            break
        }
        return resultData
      },
      // 获取租赁结束日期
      async handleRentalEndDate() {
        let {
          startDate,
          monthNum,
          dayNum
        } = this.confirmParamsInfo
        if (startDate && (monthNum || dayNum)) {
          await this.getLastDateData()
          let { endDate } = this.confirmParamsInfo
          if (endDate) {
            let expectedResultData = await this.validateExpectedDate()
            if (!expectedResultData.effective) {
              await this.initExpectedDateParams()
              this.$notify({
                type: 'warning',
                message: expectedResultData.message
              })
            }
            this.expectedDateInfo.minDate = new Date(dayjs(startDate))
            this.expectedDateInfo.maxDate = new Date(dayjs(endDate))
            await this.initHouseParams()
            await this.getHouseListData()

            /* let resultData = await this.validateStartEndDate()
            if (resultData.effective) {
              // 判断选择的日期是否有效
              let expectedResultData = await this.validateExpectedDate()
              if (!expectedResultData.effective) {
                await this.initExpectedDateParams()
                this.$notify({
                  type: 'warning',
                  message: expectedResultData.message
                })
              }
              this.expectedDateInfo.minDate = new Date(dayjs(startDate))
              this.expectedDateInfo.maxDate = new Date(dayjs(endDate))
              await this.initHouseParams()
              await this.getHouseListData()
            } else {
              // 如果无效
              await this.initRentalDateParams()
              await this.initExpectedDateParams()
              this.expectedDateInfo.minDate = new Date()
              this.expectedDateInfo.maxDate = new Date(dayjs().add(6, 'year'))
              await this.initHouseParams()
              this.houseListData = []
              this.$notify({
                type: 'warning',
                message: resultData.message
              })
            } */
          }
        }
      },
      // 获取管家下拉选列表
      getStewardsList() {
        let { dcProjectId } = this.projectInfo
        getStewardsListByProject({ projectId: dcProjectId }).then(res => {
          if (res.data.code == '10000') {
            this.stewardsInfo.listData = res.data.data
          }
        })
      },
      // 房间号
      onSearch() {
        this.$store.dispatch('base/SetLoading', true)
        let { dcProjectId } = this.projectInfo
        getRoomIdAPI({ projectId: dcProjectId, roomNo: this.roomNo }).then((res) => {
          let isObjectEmpty = Object.keys(res.data.data).length === 0
          if (isObjectEmpty) {
            this.$toast('未查到续签房源')
          } else {
            this.initData()
            this.detailInfo = res.data.data
            this.detailInfo.status = '2'
            this.detailInfo.baseRoomTypeEnt = {
              projectName: res.data.data.projectName
            }
            this.detailInfo.contractVO = {
              roomNo: res.data.data.roomNo
            }
            this.confirmParamsInfo.realName = res.data.data.realName
            this.confirmParamsInfo.mobile = res.data.data.mobile
            this.confirmParamsInfo.idCard = res.data.data.idCard
            this.confirmParamsInfo.idCardNoType = res.data.data.idCardNoType
            this.confirmParamsInfo.startDate = dayjs(res.data.data.endDate).add(1, 'day').format('YYYY-MM-DD')
            this.confirmParamsInfo.expectedDate = dayjs(res.data.data.endDate).add(1, 'day').format('YYYY-MM-DD')
            this.confirmParamsInfo.deposit = res.data.data.deposit
            // this.confirmParamsInfo.monthlyRent = resultData.contractVO.monthlyRent
            this.confirmParamsInfo.space = res.data.data.space
            this.confirmParamsInfo.roomNo = res.data.data.roomNo
            this.confirmParamsInfo.projectName = res.data.data.projectName
            this.confirmParamsInfo.owner = res.data.data.owner
            this.confirmParamsInfo.houseCode = res.data.data.houseCode
            this.confirmParamsInfo.mailingAddress = res.data.data.mailingAddress
            this.confirmParamsInfo.email = res.data.data.email
            this.confirmParamsInfo.emergencyContactName = res.data.data.emergencyContactName
            this.confirmParamsInfo.emergencyContactPhone = res.data.data.emergencyContactPhone
            this.confirmParamsInfo.ifCompanionTenant = res.data.data.ifCompanionTenant
            this.confirmParamsInfo.companionDetailSubList = res.data.data.companionDetailSubList
            this.confirmParamsInfo.tenantSource = res.data.data.tenantSource
            this.confirmParamsInfo.profession = res.data.data.bookerProfession
          }
        }).finally(() => {
          this.$store.dispatch('base/SetLoading', false)
        })
      },
      // 复制
      copyBeforeClose(action, done) {
        // if (action === 'confirm') {
        //   done(false)
        //   return
        // }
        done(false)
      },
      handleCopyCancel() {
        this.copyInfo.show = false
        this.$router.go(-1)
      },
      handleCopyConfirm() {
        let copyText = document.getElementsByClassName('copy-text')[0] // 获取需要复制的内容
        let textarea = document.createElement('textarea') // 创建一个 textarea 元素
        textarea.style.width = '0'
        textarea.style.height = '0'
        textarea.style.opacity = '0'
        document.body.appendChild(textarea)
        textarea.value = `您的合同已生成，请点击签署：${copyText.textContent}` // 将需要复制的内容设置为 textarea 的 value 属性
        textarea.select() // 选中 textarea 中的内容
        document.execCommand('copy') // 将选中的内容复制到剪贴板中
        this.$toast('复制成功')
        document.body.removeChild(textarea) // 将 textarea 从页面中移除
      },
      // 同住人
      handleRoommateChange() {
        if (this.confirmParamsInfo.contractType === '新签' && this.confirmParamsInfo.ifCompanionTenant == 0) {
          this.confirmParamsInfo.companionDetailSubList = []
        } else if (this.confirmParamsInfo.contractType === '续签') {

        }
      },
      handleRoommateAdd() {
        this.confirmParamsInfo.companionDetailSubList.push({
          companionName: '', // 姓名
          companionIdCardNo: '', // 身份证号码
          companionPhone: '', // 手机号码
          companionRelation: '', // 关系
          companionAge: '', // 年龄
          companionSex: '男', // 性别
          companionNative: '', // 籍贯
          companionHomeAddress: '', // 家庭地址
          companionEmergencyContactName: '', // 同住人紧急联系人姓名
          companionEmergencyContactPhone: '' // 同住人紧急联系人电话
        })
      },
      handleRoommateDel(index) {
        this.confirmParamsInfo.companionDetailSubList.splice(index, 1)
        if (this.confirmParamsInfo.companionDetailSubList.length === 0) {
          this.confirmParamsInfo.ifCompanionTenant = 0
        }
      },
      // 输入整月数量
      handleInputMonthNumber: debounce(async function(e) {
        if (this.isShowDay && !e && this.confirmParamsInfo && !this.confirmParamsInfo.dayNum) {
          this.confirmParamsInfo.endDate = ''
          this.houseListData = []
          return
        }

        this.resetCouponInfo()
        if (this.status === '1') {
          await this.handleRentalEndDate()
        }
        if (this.status === '2') {
          const {
            houseCode,
            monthNum,
            dayNum,
            modeOfPayment
          } = this.confirmParamsInfo
          if (houseCode && (monthNum || dayNum)) {
            let floorPriceRes = await getFloorPriceAPI({
              houseId: houseCode,
              rentMonth: monthNum || (dayNum && 1),
              modeOfPayment
            })
            if (floorPriceRes.data.code === '10000') {
              this.floorPriceInfo.data = floorPriceRes.data.data
              console.log(floorPriceRes.data.data.currentPrice, 'floorPriceRes.data.data.currentPrice')
              this.confirmParamsInfo.monthlyRent = floorPriceRes.data.data.currentPrice
              this.confirmParamsInfo.totalFee = floorPriceRes.data.data.currentPrice || 0
              if (this.confirmParamsInfo.monthlyRent < floorPriceRes.data.data.minAmount) {
                this.confirmParamsInfo.monthlyRentMSG = `签约价不可低于底价:${this.floorPriceInfo.data.minAmount}元`
              }
            }
          }
          await this.getLastDateData()
        }
        // 优惠方案
        if (this.confirmParamsInfo.monthlyRent && this.confirmParamsInfo.monthNum) {
          this.getCouponFanAnList()
        }
        // 优惠券
        if (this.confirmParamsInfo.monthlyRent && this.confirmParamsInfo.monthNum && this.confirmParamsInfo.actCouponProgrammeId) {
          this.getCoupon()
        }
      }, 300),
      handleInputDayNumber: debounce(async function(e) {
        if (this.isShowDay && !e && this.confirmParamsInfo && !this.confirmParamsInfo.monthNum) {
          this.confirmParamsInfo.endDate = ''
          this.houseListData = []
          return
        }

        if (e > 31) {
          this.$notify({
            type: 'warning',
            message: '天数不能大于31天！'
          })
          this.confirmParamsInfo.dayNum = ''
          return
        }

        this.resetCouponInfo()
        if (this.status === '1') {
          await this.handleRentalEndDate()
        }

        if (this.status === '2') {
          const {
            houseCode,
            monthNum,
            modeOfPayment
          } = this.confirmParamsInfo
          if (houseCode && monthNum) {
            let floorPriceRes = await getFloorPriceAPI({
              houseId: houseCode,
              rentMonth: monthNum,
              modeOfPayment
            })
            if (floorPriceRes.data.code === '10000') {
              this.floorPriceInfo.data = floorPriceRes.data.data
              this.confirmParamsInfo.monthlyRent = floorPriceRes.data.data.currentPrice
              this.confirmParamsInfo.totalFee = floorPriceRes.data.data.currentPrice || 0
              if (this.confirmParamsInfo.monthlyRent < floorPriceRes.data.data.minAmount) {
                this.confirmParamsInfo.monthlyRentMSG = `签约价不可低于底价:${this.floorPriceInfo.data.minAmount}元`
              }
            }
          }
          await this.getLastDateData()
        }
        // 优惠方案
        if (this.confirmParamsInfo.monthlyRent && this.confirmParamsInfo.monthNum) {
          this.getCouponFanAnList()
        }
        // 优惠券
        if (this.confirmParamsInfo.monthlyRent && this.confirmParamsInfo.monthNum && this.confirmParamsInfo.actCouponProgrammeId) {
          this.getCoupon()
        }
      }, 500),
      // 房源列表
      handleHouseTypeCancel() {
        // if (!this.detailInfo.status) return
        this.houseTypeInfo.show = false
      },
      handleIdCardNoTypeConfirm(value) {
        let info = this.idCardNoTypeInfo.allListData.find(item => item.label == value)
        this.confirmParamsInfo.idCardNoTypeName = info.label
        this.confirmParamsInfo.idCardNoType = info.value
        this.idCardNoTypeInfo.show = false
      },
      handleHouseTypeConfirm(value) {
        let info = this.houseTypeInfo.allListData.find(item => item.roomTypeName === value)
        this.detailInfo = info
        this.detailInfo.baseRoomTypeEnt = {
          area: info.area,
          coverImg: info.coverImg,
          houseType: info.houseType,
          monthRentPrice: info.monthRentPrice,
          projectName: info.projectName,
          roomArea: info.roomArea,
          roomTypeName: info.roomTypeName,
          sheet: info.sheet
        }
        this.detailInfo.status = '1'
        this.houseTypeInfo.show = false
        this.initData()
      },
      // 选择管家
      handleSelectSteward(value) {
        this.stewardsInfo.show = false
        this.$set(this.confirmParamsInfo, 'serviceByName', value.realname)
        this.$set(this.confirmParamsInfo, 'serviceById', value.id)
      },
      // 选择租赁开始日期
      async handleSelectCalendar(date) {
        this.confirmCalendarInfo.show = false
        this.confirmCalendarInfo.date = date
        this.confirmParamsInfo.startDate = date
        await this.handleRentalEndDate()
      },
      // 批次
      async handleBatch(value) {
        this.batchInfo.show = false
        this.confirmParamsInfo.batchId = value.id
        this.confirmParamsInfo.batchName = value.batchName
        this.getSigningDays()
      },
      // 客户来源
      async handleRentalSourceType(value) {
        this.clinchDealSourceInfo.show = false
        this.confirmParamsInfo.tenantSource = value.value
      },
      getProfessionList() {
        let params = {
          dictCode: 'customer_type',
          projectId: this.projectInfo.dcProjectId,
          paramName: this.professionInfo.search
        }
        getProfessionAPI(params).then(res => {
          this.professionInfo.listData = res.data.data.map(item => {
            return item.text
          })
          this.professionInfo.allListData = res.data.data
        })
      },
      // 职业
      handleProfessionSearch: debounce(function(val) {
        this.getProfessionList()
      }, 500),
      async handleProfessionInfo(value) {
        this.professionInfo.show = false
        let obj = this.professionInfo.allListData.find(item => item.text === value)
        console.log(obj, 'obj')
        let _this = this
        if (obj) this.$set(_this.confirmParamsInfo, 'profession', obj.value)
      },
      // 预计入住日期
      async handleSelectExpectedDate(date) {
        this.expectedDateInfo.show = false
        this.expectedDateInfo.date = date
        this.confirmParamsInfo.expectedDate = date
        console.log(this.confirmParamsInfo.expectedDate, 'this.confirmParamsInfo.expectedDate')
      },
      // 每月综合服务费
      handleMonthPriceInput: debounce(function(e) {
        console.log(e, 111, 'e000')
        if (!this.confirmParamsInfo.endDate) {
          this.confirmParamsInfo.monthlyRent = ''
          this.$toast('请输入整月数或整天数！')
          return
        }
        this.$store.dispatch('base/SetLoading', true)
        this.resetCouponInfo(e)

        let query = {
          projectId: this.userInfo.dcProjectId,
          packageId: this.confirmParamsInfo.packageId || undefined,
          startDate: dayjs(this.confirmParamsInfo.startDate).format('YYYY-MM-DD'),
          endDate: dayjs(this.confirmParamsInfo.endDate).format('YYYY-MM-DD'),
          monthlyRent: this.confirmParamsInfo.monthlyRent
        }
        getMonthPrice(query).then(res => {
          if (res.data.code === '10000') {
            this.confirmParamsInfo.totalFee = res.data.data.totalFee
            this.confirmParamsInfo.priceDes = res.data.data.priceDes
          }
        }).finally(() => {
          this.$store.dispatch('base/SetLoading', false)
        })
      }, 300),
      // 入住时电表抄表日期
      async handleSelectElectricDateInfoDate(date) {
        this.electricDateInfo.show = false
        this.electricDateInfo.date = date
        this.confirmParamsInfo.electricityRecordDate = date
        console.log(this.confirmParamsInfo.electricityRecordDate, 'this.confirmParamsInfo.electricityRecordDate')
      },
      // 入住时水表抄表日期
      async handleSelectWaterDateInfoDate(date) {
        this.waterDateInfo.show = false
        this.waterDateInfo.date = date
        this.confirmParamsInfo.waterRecordDate = date
      },
      // 图片预览
      reviewImg(img) {
        ImagePreview([img])
      },
      // 入住时水表图片上传
      afterWaterIDRead(file) {
        console.log(file)
        file.status = 'uploading'
        file.message = '上传中...'
        fileUpload(file).then(res => {
          file.status = 'done'
          const cb = res.data
          if (cb.code === 200) {
            this.confirmParamsInfo.checkInWaterPicture = cb.data.url
          }
        })
        console.log(this.confirmParamsInfo.checkInWaterPicture, 'a')
      },
      // 入住时电表图片上传
      afterElectricityIDRead(file) {
        console.log(file)
        file.status = 'uploading'
        file.message = '上传中...'
        fileUpload(file).then(res => {
          file.status = 'done'
          const cb = res.data
          if (cb.code === 200) {
            this.confirmParamsInfo.checkInElectricityPicture = cb.data.url
          }
        })
        console.log(this.confirmParamsInfo.checkInElectricityPicture, 'b')
      },
      onOversize() {
        this.$toast('文件大小不能超过5M')
      },
      deleteWaterImg(file) {
        this.confirmParamsInfo.checkInWaterPicture = ''
      },
      deleteElectricityrImg(file) {
        this.confirmParamsInfo.checkInElectricityPicture = ''
      },
      // 查看合同
      async handleViewContract() {
        let { detailInfo } = this
        window.open(detailInfo.oosPdf)
        /* this.$router.push({
          name: 'FileIndex',
          query: {
            fileUrl: detailInfo.viewPdfUrl
          }
        }) */
      },
      // 是否携带宠物
      async handleCarryPet({ isShowLoading } = { isShowLoading: true }) {
        try {
          this.isLoading = true
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          let { dcProjectId } = this.projectInfo
          let response = await getPetDepositAPI({ projectId: dcProjectId })

          if (`${response.data.code}` === '10000') { // 请求成功
            let resultData = response.data.data || ''
            this.confirmParamsInfo.petDeposit = resultData
            await this.handleCallback({
              isError: false
            })
            return Promise.resolve(response)
          } else {
            // 请求失败
            await this.handleCallback({
              isError: true,
              errorMessage: result.msg || '获取失败!'
            })
            return Promise.reject(new Error(result.msg || '获取失败!'))
          }
        } catch (error) {
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '获取失败!'
          })
          return Promise.reject(error)
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      // 选择租赁类型
      async handleSelectRentalType(value) {
        this.rentalTypeInfo.show = false
        this.confirmParamsInfo.modeOfPayment = value
        /* let resultData = await this.validateStartEndDate()
        if (!resultData.effective) {
          // 如果无效
          await this.initRentalDateParams()
          await this.initExpectedDateParams()
          this.expectedDateInfo.minDate = new Date()
          this.expectedDateInfo.maxDate = new Date(dayjs().add(6, 'year'))
          await this.initHouseParams()
          this.houseListData = []
          this.$notify({
            type: 'warning',
            message: resultData.message
          })
        } */
      },
      // 选择优惠方案
      handleOpenCouponScheme() {
        if (!this.confirmParamsInfo.monthNum) {
          this.$toast({
            message: '请输入整月数量！'
          })
          return
        }

        if (!this.confirmParamsInfo.monthlyRent) {
          this.$toast({
            message: '请输入房屋每月综合服务费！'
          })
          return
        }
        this.couponSchemeInfo.show = true
        this.getCouponFanAnList()
      },
      getCouponFanAnList() {
        let { dcProjectId } = this.projectInfo
        let amount = Number(this.confirmParamsInfo.monthlyRent)
        getCouponSchemeList({
          projectIds: dcProjectId,
          monthLimit: this.confirmParamsInfo.monthNum,
          amount
        }).then((res) => {
          this.couponSchemeInfo.listData = res.data.data
          console.log(res, 'res')
        })
      },
      handleSelectCouponScheme(value) {
        this.couponSchemeInfo.show = false
        this.confirmParamsInfo.actCouponProgrammeId = value.id
        this.confirmParamsInfo.actCouponProgrammeName = value.name
        // 优惠券
        if (this.confirmParamsInfo.monthlyRent && this.confirmParamsInfo.monthNum && this.confirmParamsInfo.actCouponProgrammeId) {
          this.getCoupon()
        }
      },
      // 选择优惠券
      handleOpenCoupon() {
        if (!this.confirmParamsInfo.monthNum) {
          this.$toast({
            message: '请输入整月数量！'
          })
          return
        }

        if (!this.confirmParamsInfo.monthlyRent) {
          this.$toast({
            message: '请输入房屋每月综合服务费！'
          })
          return
        }

        if (!this.confirmParamsInfo.actCouponProgrammeId) {
          this.$toast({
            message: '请选择优惠券方案！'
          })
          return
        }
        this.couponInfo.show = true
        this.getCoupon()
      },
      getCoupon() {
        let { dcProjectId } = this.projectInfo
        let amount = Number(this.confirmParamsInfo.monthlyRent)
        getCouponList({
          projectId: dcProjectId,
          actCouponProgrammeId: this.confirmParamsInfo.actCouponProgrammeId,
          monthLimit: this.confirmParamsInfo.monthNum,
          amount,
          contractType: this.detailInfo.contractType
        }).then((res) => {
          this.couponInfo.listData = res.data.data
        })
      },
      handleSelectCoupon(value) {
        this.couponInfo.show = false
        this.confirmParamsInfo.couponId = value.couponId
        this.confirmParamsInfo.couponName = value.couponName
      },
      // 选择房源
      async handleSelectHouse(data) {
        let { houseListData } = this
        houseListData.map((item, index) => {
          this.$set(houseListData[index], 'isSelected', item.houseCode === data.houseCode)
        })
        this.confirmParamsInfo.space = data.space
        this.confirmParamsInfo.roomNo = data.roomNo
        this.confirmParamsInfo.projectName = data.projectName
        this.confirmParamsInfo.owner = data.landlordName
        this.confirmParamsInfo.houseCode = data.houseCode
        // 获取房源底价
        const {
          houseCode,
          monthNum,
          dayNum,
          modeOfPayment
        } = this.confirmParamsInfo
        let floorPriceRes = await getFloorPriceAPI({
          houseId: houseCode,
          rentMonth: monthNum || (dayNum && 1),
          modeOfPayment
        })
        if (floorPriceRes.data.code === '10000') {
          this.floorPriceInfo.data = floorPriceRes.data.data
          this.confirmParamsInfo.deposit = floorPriceRes.data.data.currentPrice || 0
          this.confirmParamsInfo.monthlyRent = floorPriceRes.data.data.currentPrice || 0
          this.confirmParamsInfo.totalFee = floorPriceRes.data.data.currentPrice || 0
        }
      },
      // 客户来源是否可编辑
      async handleClinchDealSourceInfoShow() {
        this.clinchDealSourceInfo.show = this.setTenantSource
      },
      // 职业
      handleProfessionInfoShow() {
        this.professionInfo.show = true
      },
      // 租金底价原因
      floorPriceBeforeClose(action, done) {
        if (action === 'confirm') {
          if (!this.confirmParamsInfo.amountRemark) {
            this.$notify({
              type: 'warning',
              message: '请输入原因！'
            })
            done(false)
          } else {
            done()
            this.handleConfirmSignContract()
          }
        } else {
          this.confirmParamsInfo.amountRemark = ''
          done()
        }
      },
      // 取消签约
      handleCancelSignContract: debounce(function() {
        this.$dialog.confirm({
          title: '取消签约',
          message: '是否确认取消签约？',
          confirmButtonColor: '#D8B084'
        }).then(async () => {
          // on confirm
          await this.cancelSignContractData({
            isShowLoading: true,
            contractId: this.contractId
          })

          this.$toast({
            message: '提交成功！',
            onClose: () => {
              this.$router.go(-1)
            }
          })
        }).catch(() => {
          // on cancel
        })
      }, 200),
      // 确认信息
      handleConfirmSignContract: debounce(function() {
        let {
          realName,
          mobile,
          idCard,
          mailingAddress,
          email,
          emergencyContactName,
          emergencyContactPhone,
          idCardNoType,
          startDate,
          endDate,
          expectedDate,
          tenantSource,
          monthlyRent,
          dayNum,
          deposit,
          modeOfPayment,
          houseCode,
          ifCompanionTenant,
          companionDetailSubList,
          beneficiary,
          serviceByName,
          totalFee
        } = this.confirmParamsInfo
        let isCanSubmit = true
        this.$notify.clear()
        // 黑名单
        if (this.isInBlackInfo.show) {
          this.$notify({
            type: 'warning',
            message: this.isInBlackInfo.des
          })
          return
        }
        if (realName === '') {
          this.$notify({
            type: 'warning',
            message: '请输入姓名！'
          })
          isCanSubmit = false
        } else if (mobile === '') {
          this.$notify({
            type: 'warning',
            message: '请输入手机号！'
          })
          isCanSubmit = false
        } else if (idCardNoType === '' || idCardNoType === undefined) {
          this.$notify({
            type: 'warning',
            message: '请选择证件类型！'
          })
          isCanSubmit = false
        } else if (idCard === '') {
          this.$notify({
            type: 'warning',
            message: '请输入证件号！'
          })
          isCanSubmit = false
        } else if (mailingAddress === '') {
          this.$notify({
            type: 'warning',
            message: '请输入签约人地址'
          })
          isCanSubmit = false
        } else if (email === '') {
          this.$notify({
            type: 'warning',
            message: '请输入邮件'
          })
          isCanSubmit = false
        } else if (emergencyContactName === '') {
          this.$notify({
            type: 'warning',
            message: '请输入紧急联系人姓名'
          })
          isCanSubmit = false
        } else if (emergencyContactPhone === '') {
          this.$notify({
            type: 'warning',
            message: '请输入紧急联系人联系方式'
          })
          isCanSubmit = false
        } else if (startDate === '' || endDate === '') {
          this.$notify({
            type: 'warning',
            message: '请选择租赁开始和结束日期！'
          })
          isCanSubmit = false
        } else if (tenantSource === '') {
          this.$notify({
            type: 'warning',
            message: '请选择客户来源！'
          })

          isCanSubmit = false
        } else if (expectedDate === '') {
          this.$notify({
            type: 'warning',
            message: '请选择预计入住日期，在租赁日期范围内！'
          })
          isCanSubmit = false
        } else if (monthlyRent === '' && dayNum === '') {
          this.$notify({
            type: 'warning',
            message: '请输入每月综合服务费！'
          })
          isCanSubmit = false
        } else if (totalFee === 0) {
          this.$notify({
            type: 'warning',
            message: '请输入每月综合服务费！'
          })
          isCanSubmit = false
        } else if (deposit === '') {
          this.$notify({
            type: 'warning',
            message: '请输入房屋保证金！'
          })
          isCanSubmit = false
        } else if (modeOfPayment === '') {
          this.$notify({
            type: 'warning',
            message: '请选择租赁类型！'
          })
          isCanSubmit = false
        } else if (houseCode === '') {
          this.$notify({
            type: 'warning',
            message: '请选择房源！'
          })
          isCanSubmit = false
        } else if (ifCompanionTenant == 1) {
          if (companionDetailSubList.length === 0) {
            this.$notify({
              type: 'warning',
              message: '请添加同住人！'
            })
            isCanSubmit = false
          }
          companionDetailSubList.forEach(item => {
            if (item.companionName === '') {
              this.$notify({
                type: 'warning',
                message: '请填写完同住人姓名！'
              })
              isCanSubmit = false
              return true
            } else if (item.companionIdCardNo === '') {
              this.$notify({
                type: 'warning',
                message: '请填写完同住人身份证号码！'
              })
              isCanSubmit = false
              return true
            } else if (item.companionPhone === '') {
              this.$notify({
                type: 'warning',
                message: '请填写完同住人手机号码！'
              })
              isCanSubmit = false
              return true
            } else if (item.companionRelation === '') {
              this.$notify({
                type: 'warning',
                message: '请填写完同住人关系！'
              })
              isCanSubmit = false
              return true
            } else if (this.detailInfo.reportState == 1 && beneficiary == '') {
              this.$notify({
                type: 'warning',
                message: '请选择主要成交参与负责人员！'
              })
              isCanSubmit = false
            } else if (serviceByName === '' || serviceByName === undefined) {
              this.$notify({
                type: 'warning',
                message: '请选择服务管家！'
              })
              isCanSubmit = false
            }
          })
        }

        if (isCanSubmit) {
          // 增加confirm确认
          this.$dialog.confirm({
            title: '确认信息',
            message: '是否确认信息？',
            confirmButtonColor: '#D8B084'
          }).then(async () => {
            try {
              await this.$store.dispatch('base/SetLoading', true)
              // 是否需要申请审核
              let {
                confirmParamsInfo,
                reportData
              } = this
              let { roomTypeName, roomTypeId, renewalContractId } = this.detailInfo
              let { dcProjectId } = this.projectInfo
              confirmSignContractOfflineAPI({
                ...confirmParamsInfo,
                ...reportData,
                ...{
                  projectId: dcProjectId,
                  roomTypeId,
                  renewalContractId,
                  roomTypeName,
                  startDate: dayjs(confirmParamsInfo.startDate).format('YYYY-MM-DD'),
                  endDate: dayjs(confirmParamsInfo.endDate).format('YYYY-MM-DD'),
                  expectedDate: dayjs(confirmParamsInfo.expectedDate).format('YYYY-MM-DD'),
                  electricityRecordDate: confirmParamsInfo.electricityRecordDate ? dayjs(confirmParamsInfo.electricityRecordDate).format('YYYY-MM-DD HH:mm:ss') : null,
                  waterRecordDate: confirmParamsInfo.waterRecordDate ? dayjs(confirmParamsInfo.waterRecordDate).format('YYYY-MM-DD HH:mm:ss') : null,
                  houseId: confirmParamsInfo.houseCode,
                  monthNum: this.confirmParamsInfo.monthNum ? this.confirmParamsInfo.monthNum : (this.confirmParamsInfo.dayNum && 0),
                  dayNum: this.confirmParamsInfo.dayNum ? this.confirmParamsInfo.dayNum : undefined
                }
              }).then((res) => {
                this.$toast({
                  message: '提交成功！',
                  onClose: () => {
                    this.copyInfo.show = true
                    this.copyInfo.value = res.data.data || ''
                  }
                })
              })
            } catch (error) {
              await this.handleCallback({
                isError: true,
                errorMessage: error.message || '获取失败!'
              })
              return Promise.reject(error)
            } finally {
              await this.$store.dispatch('base/SetLoading', false)
            }
            // on confirm
          }).catch(() => {
            // on cancel
          })
        }
      }, 200),
      async handleCallback({
                             isError,
                             errorMessage = undefined
                           }) { // 回调处理
        if (isError) {
          this.$toast(errorMessage || '获取失败!')
          /* if (errorMessage && errorMessage.includes('timeout')) {
            // 判断是否是网络请求超时
            this.isRefreshPage = true
            this.noDataMessage = '网络请求超时'
          } else if (errorMessage && errorMessage.includes('502')) {
            // 判断是否是服务器错误
            this.isRefreshPage = true
            this.noDataMessage = '服务器错误，502错误'
          } */
        }
      },
      async getHouseListData({ isShowLoading } = { isShowLoading: true }) { // 获取房源列表
        try {
          this.isLoading = true
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          let { dcProjectId } = this.projectInfo
          let {
            startDate,
            endDate,
            batchId
          } = this.confirmParamsInfo
          let { roomTypeName } = this.detailInfo
          // startDate = startDate || new Date()
          // endDate = endDate || new Date(dayjs().add(1, 'year'))
          let response = await getHouseListAPI({
            startDate: dayjs(startDate).format('YYYY-MM-DD'),
            endDate: dayjs(endDate).format('YYYY-MM-DD'),
            projectId: dcProjectId,
            roomTypeName,
            batchId
          })
          let result = response.data
          // console.log(result)
          if (`${result.code}` === '10000') { // 请求成功
            // 接口请求处理成功之后回跳
            this.houseListData = result.data || []
            return Promise.resolve(response)
          } else {
            // 请求失败
            await this.handleCallback({
              isError: true,
              errorMessage: result.msg || '获取失败!'
            })
            return Promise.reject(new Error(result.msg || '获取失败!'))
          }
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '获取失败!'
          })
          return Promise.reject(error)
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      async confirmSignContractData({ isShowLoading } = { isShowLoading: true }) { // 完成接待
        try {
          this.isLoading = true
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          let {
            confirmParamsInfo,
            contractId
          } = this
          let { roomTypeName } = this.detailInfo
          let response = null
          if (`${this.status}` === '1') {
            response = await confirmSignContractAPI({
              ...confirmParamsInfo,
              ...{
                contractId: `${contractId}`,
                roomTypeName,
                startDate: dayjs(confirmParamsInfo.startDate).format('YYYY-MM-DD'),
                endDate: dayjs(confirmParamsInfo.endDate).format('YYYY-MM-DD'),
                expectedDate: dayjs(confirmParamsInfo.expectedDate).format('YYYY-MM-DD'),
                electricityRecordDate: dayjs(confirmParamsInfo.electricityRecordDate).format('YYYY-MM-DD HH:mm:ss'),
                waterRecordDate: dayjs(confirmParamsInfo.waterRecordDate).format('YYYY-MM-DD HH:mm:ss'),
                houseId: confirmParamsInfo.houseCode,
                monthNum: this.confirmParamsInfo.monthNum ? this.confirmParamsInfo.monthNum : (this.confirmParamsInfo.dayNum && 0),
                dayNum: this.confirmParamsInfo.dayNum ? this.confirmParamsInfo.dayNum : undefined
              }
            })
          } else {
            response = await renewalConfirmSignContractAPI({
              ...confirmParamsInfo,
              ...{
                contractId: `${contractId}`,
                roomTypeName,
                startDate: dayjs(confirmParamsInfo.startDate).format('YYYY-MM-DD'),
                endDate: dayjs(confirmParamsInfo.endDate).format('YYYY-MM-DD'),
                expectedDate: dayjs(confirmParamsInfo.expectedDate).format('YYYY-MM-DD'),
                houseId: confirmParamsInfo.houseCode
              }
            })
          }
          let result = response.data
          // console.log(result)
          if (`${result.code}` === '10000') { // 请求成功
            // 接口请求处理成功之后回跳
            return Promise.resolve(response)
          } else {
            // 请求失败
            await this.handleCallback({
              isError: true,
              errorMessage: result.msg || '请求失败!'
            })
            return Promise.reject(new Error(result.msg || '请求失败!'))
          }
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '完成接待失败!'
          })
          return Promise.reject(error)
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      async getDetailData({ isShowLoading } = { isShowLoading: true }) { // 获取详情数据
        try {
          this.isLoading = true
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          let { contractId } = this
          let response = await getDetailAPI({
            contractId
          })
          let result = response.data
          // console.log(result)
          if (`${result.code}` === '10000') { // 请求成功
            let resultData = result.data || {}
            this.detailInfo = resultData
            if (resultData.tenantSource) {
              this.confirmParamsInfo.tenantSource = resultData.tenantSource
              this.setTenantSource = false
            }
            // 续签初始化一些数据 by yangjie
            if (`${this.status}` === '2') {
              this.confirmParamsInfo.startDate = resultData.contractVO.startDate
              this.confirmParamsInfo.expectedDate = resultData.contractVO.startDate
              this.confirmParamsInfo.deposit = resultData.contractVO.deposit
              // this.confirmParamsInfo.monthlyRent = resultData.contractVO.monthlyRent
              this.confirmParamsInfo.space = resultData.contractVO.space
              this.confirmParamsInfo.roomNo = resultData.contractVO.roomNo
              this.confirmParamsInfo.projectName = resultData.contractVO.projectName
              this.confirmParamsInfo.owner = resultData.contractVO.owner
              this.confirmParamsInfo.houseCode = resultData.contractVO.houseId
              // 获取房源底价
            }
            await this.handleCallback({
              isError: false
            })
            return Promise.resolve(response)
          } else {
            // 请求失败
            await this.handleCallback({
              isError: true,
              errorMessage: result.msg || '获取失败!'
            })
            return Promise.reject(new Error(result.msg || '获取失败!'))
          }
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '获取失败!'
          })
          return Promise.reject(error)
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      getProjectAllowDay() {
        const params = {
          projectId: this.projectInfo.dcProjectId
        }
        return getProjectAllowDayApi(params).then(res => {
          let resultData = res.data
          if (resultData.code === '10000') {
            this.isShowDay = resultData.data.allowDayContract === 2
          }
        })
      },
      async getLastDateData({ isShowLoading } = { isShowLoading: true }) { // 获取最后一月日期
        try {
          this.isLoading = true
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          let {
            startDate,
            monthNum,
            dayNum
          } = this.confirmParamsInfo
          let response = await getLastDateAPI({
            startDate: dayjs(startDate).format('YYYY-MM-DD'),
            monthNum,
            dayNum: this.isShowDay ? dayNum : undefined
          })
          let result = response.data
          // console.log(result)
          if (`${result.code}` === '10000') { // 请求成功
            let resultData = result.data || ''
            this.confirmParamsInfo.endDate = new Date(resultData)
            await this.handleCallback({
              isError: false
            })
            return Promise.resolve(response)
          } else {
            // 请求失败
            await this.handleCallback({
              isError: true,
              errorMessage: result.msg || '获取失败!'
            })
            return Promise.reject(new Error(result.msg || '获取失败!'))
          }
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '获取失败!'
          })
          return Promise.reject(error)
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      async getBatchListData({ isShowLoading } = { isShowLoading: true }) {
        try {
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          let { dcProjectId } = this.projectInfo
          let { roomTypeName } = this.detailInfo.baseRoomTypeEnt
          let response = await getBatchList({
            projectId: dcProjectId,
            layout: roomTypeName
          })
          let result = response.data
          if (`${result.code}` === '200') { // 请求成功
            let resultData = result.data || []
            this.batchInfo.listData = resultData
            if (resultData.length) {
              this.confirmParamsInfo.batchId = resultData[0].id
              this.confirmParamsInfo.batchName = resultData[0].batchName
              this.getSigningDays()
            }
          }
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '获取失败!'
          })
          return Promise.reject(error)
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      async getClinchDealData({ isShowLoading } = { isShowLoading: true }) { // 获取详情数据
        try {
          this.isLoading = true
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          let response = await getClinchDealSouceAPI()
          let result = response.data
          if (`${result.code}` === '10000') { // 请求成功
            let resultData = result.data || []
            this.clinchDealSourceInfo.listData = resultData
            await this.handleCallback({
              isError: false
            })
            return Promise.resolve(response)
          } else {
            // 请求失败
            await this.handleCallback({
              isError: true,
              errorMessage: result.msg || '获取失败!'
            })
            return Promise.reject(new Error(result.msg || '获取失败!'))
          }
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '获取失败!'
          })
          return Promise.reject(error)
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      async getPackagePriceData({ isShowLoading } = { isShowLoading: true }) { // 获取详情数据
        try {
          this.isLoading = true
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          const { dcProjectId } = this.projectInfo
          let response = await getPackagePriceAPI({ projectId: dcProjectId })
          let result = response.data
          if (`${result.code}` === '10000') { // 请求成功
            let resultData = result.data
            this.isPackagePrice = resultData

            await this.handleCallback({
              isError: false
            })
            return Promise.resolve(response)
          } else {
            // 请求失败
            await this.handleCallback({
              isError: true,
              errorMessage: result.msg || '获取失败!'
            })
            return Promise.reject(new Error(result.msg || '获取失败!'))
          }
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '获取失败!'
          })
          return Promise.reject(error)
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      async getFloorPriceData({ isShowLoading } = { isShowLoading: true }) { // 获取底价
        try {
          this.isLoading = true
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          const {
            houseCode,
            monthlyRent,
            modeOfPayment
          } = this.confirmParamsInfo
          let response = await getFloorPriceAPI({ houseId: houseCode, modeOfPayment })
          let result = response.data
          if (`${result.code}` === '10000') {
            let resultData = result.data
            this.floorPriceInfo.data = resultData

            await this.handleCallback({
              isError: false
            })
            return Promise.resolve(response)
          } else {
            // 请求失败
            await this.handleCallback({
              isError: true,
              errorMessage: result.msg || '获取失败!'
            })
            return Promise.reject(new Error(result.msg || '获取失败!'))
          }
        } catch (error) {
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '获取失败!'
          })
          return Promise.reject(error)
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      async initData() {
        await this.getProfessionList()
        await this.initHouseParams()
        // await this.getDetailData()
        // 是否支持天数选择
        await this.getProjectAllowDay()
        await this.getClinchDealData()
        await this.getBatchListData()
        await this.getPackagePriceData()
        await this.getStewardsList()
        // 续签显示上次成交价
        if (this.detailInfo.contractType === 2) {
          await this.getHousePriceByRentData()
        }
        // 客户来源列表
        // const responseSource = await getClinchDealSouceAPI();
        // console.log(responseSource, 'responseSource')

        /* let { status } = this
        if (`${status}` === '1') {
          await this.getHouseListData()
        } */
        await this.getFVF()
      },
      getFVF() {
        let { dcProjectId } = this.projectInfo
        getPtfwf({ projectId: dcProjectId }).then(res => {
          if (res.data.success) {
            this.amountLjqyf = res.data.result.amountLjqyf || 0
            this.amountPtfwf = res.data.result.amountPtfwf || 0
          } else {
            this.$toast(res.data.message)
          }
        })
      },
      getHousePriceByRentData() {
        getHousePriceByRentId({
          rentId: this.detailInfo.renewalContractId
        }).then((res) => {
          let resultData = res.data
          if (resultData.code === '10000') {
            this.latestContractPrice = resultData.data.latestContractPrice
            this.latestContractMonths = resultData.data.latestContractMonths
            this.confirmParamsInfo.monthlyRent = resultData.data.latestContractPrice
          }
        })
      },
      resetCouponInfo(e) {
        this.confirmParamsInfo.actCouponProgrammeId = ''
        this.confirmParamsInfo.actCouponProgrammeName = ''
        this.confirmParamsInfo.couponId = ''
        this.confirmParamsInfo.couponName = ''
        if (this.floorPriceInfo.data && this.floorPriceInfo.data.minAmount && !this.floorPriceInfo.data.canBreakMinAmount) {
          if (Number(this.confirmParamsInfo.monthlyRent) && (Number(this.confirmParamsInfo.monthlyRent) < this.floorPriceInfo.data.minAmount)) {
            this.confirmParamsInfo.monthlyRentMSG = `签约价不可低于底价:${this.floorPriceInfo.data.minAmount}元`
          } else {
            this.confirmParamsInfo.monthlyRentMSG = ''
          }
          // if (this.latestContractMonths !== this.confirmParamsInfo.monthNum) {
          //   console.log(this.latestContractMonths, this.confirmParamsInfo.monthNum)
          //   // 续签时和上次租期不相等时
          //   if (Number(this.confirmParamsInfo.monthlyRent) && (Number(this.confirmParamsInfo.monthlyRent) < this.floorPriceInfo.data.minAmount)) {
          //     this.confirmParamsInfo.monthlyRentMSG = `签约价不可低于底价:${this.floorPriceInfo.data.minAmount}元`
          //   } else {
          //     this.confirmParamsInfo.monthlyRentMSG = ''
          //   }
          // } else {
          //   if (Number(this.confirmParamsInfo.monthlyRent) && (Number(this.confirmParamsInfo.monthlyRent) < this.latestContractPrice)) {
          //     this.confirmParamsInfo.monthlyRentMSG = `签约价不可低于上次成交价:${this.latestContractPrice}元`
          //   } else {
          //     this.confirmParamsInfo.monthlyRentMSG = ''
          //   }
          // }
        } else {
          this.confirmParamsInfo.monthlyRentMSG = ''
        }
      },
      isMonthlyRentMsg() {
        if (this.confirmParamsInfo.monthlyRentMSG) {
          this.confirmParamsInfo.monthlyRent = ''
        }
        // 优惠方案
        if (this.confirmParamsInfo.monthlyRent && this.confirmParamsInfo.monthNum) {
          this.getCouponFanAnList()
        }
        // 优惠券
        if (this.confirmParamsInfo.monthlyRent && this.confirmParamsInfo.monthNum && this.confirmParamsInfo.actCouponProgrammeId) {
          this.getCoupon()
        }
      },
      getSigningDays() {
        let { batchId } = this.confirmParamsInfo
        let { dcProjectId } = this.projectInfo
        getAllowedSigningDays({ projectId: dcProjectId, batchId }).then(res => {
          if (res.data.code == '200') {
            let day = res.data.data.allowedSigningDayDTO
            console.log(day, 'day')
            this.$refs.rentalDateCalendar.maxData = new Date(new Date().setDate(new Date().getDate() + Number(day)))
            this.$nextTick(() => {
              this.confirmCalendarInfo.maxDate = new Date(new Date().setDate(new Date().getDate() + Number(day)))
            })
          }
        })
      },
      /* 解决方案： vant组件--日历组件van-calendar初始化显示空白，滑动一下屏幕才显示（ios系统出现的问题） */
      openCalendar() {
        this.$nextTick(() => {
          const calendarDom = document.querySelector('.van-calendar__body')
          if (calendarDom) {
            let back = calendarDom.scrollTop
            setTimeout(() => {
              back = calendarDom.scrollTop
              calendarDom.scrollTop = back - 2
            }, 10)
            setTimeout(() => {
              calendarDom.scrollTop = back
            }, 100)
          }
        })
      }
    },
    filters: {
      formatParamsDate(data) {
        return data ? dayjs(data).format('YYYY年MM月DD日') : ''
      },
      formatConfirmParamsMoney(data) {
        return `${data || '-'} 元`
      },
      formatSpecialRoom(data) {
        if (data === 1) {
          return '是'
        } else if (data === 0) {
          return '否'
        }
        return ''
      },
      formatIdCardNoType(data, allListData) {
        return allListData.find(item => item.value === data).label || ''
      },
      professionName(val, that) {
        let obj = that.professionInfo.allListData.find(item => item.value === val)
        return obj ? obj.text : ''
      }
    },
    watch: {
      houseListData(newVal, oldVal) {
        // floorPriceInfo.data
        this.floorPriceInfo.data = {}
      }
    },
    created() {
    },
    mounted() {
      this.$store.dispatch('base/SetLoading', true)
      this.confirmParamsInfo.contractType = this.$route.query.contractType
      if (this.confirmParamsInfo.contractType === '新签') {
        this.houseTypeInfo.show = true
        getHouseTypeListApi({ projectId: this.projectInfo.dcProjectId })
          .then((res) => {
            this.houseTypeInfo.listData = res.data.data.map(item => item.roomTypeName)
            this.houseTypeInfo.allListData = res.data.data || []
            // this.initData()
          })
          .finally(() => {
            this.$store.dispatch('base/SetLoading', false)
          })
      } else if (this.confirmParamsInfo.contractType === '续签') {
        this.$store.dispatch('base/SetLoading', false)
      }
      // this.initData()
    },
    beforeRouteEnter(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    },
    beforeRouteUpdate(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    }
  }
</script>

<style lang='less' scoped>
  @import '../../assets/styles/themes/default/index';
  @import '../../assets/styles/mixins/index';

  @sectionPrefixCls: ~'@{css-prefix}section';

  .@{css-prefix}list {
    .@{css-prefix}list-body {
      .hairline-remove('top');
      .hairline-remove('bottom');

      .@{css-prefix}list-item {
        &:not(:first-child) {
          .hairline('top', 1px, #EEEEEE);
        }

        .@{css-prefix}list-content,
        .@{css-prefix}list-extra {
          padding: 0;
        }
      }
    }
  }

  .@{css-prefix}detail-header {
    width: 100%;
    padding: 10px 12px 0;

    &-body {
      padding-bottom: 5px;
      background-color: #C3C3C3;
      border-radius: 30px 30px 0 0;
    }

    &-status {
      display: flex;
      padding-top: 5px;
      align-items: center;
      justify-content: center;
      line-height: 30px;
      font-size: 15px;
      color: #FFFFFF;

      &-icon {
        margin-right: 5px;
      }
    }

    &-msg {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 20px;
      font-size: 12px;
      color: #FFFFFF;
    }
  }

  .@{sectionPrefixCls} {
    &-item {
      position: relative;
      overflow: hidden;

      .@{css-prefix}card-header {
        min-height: 40px;

        &-line {
          .@{css-prefix}card-header-thumb {
            margin-right: 10px;

            .svg-icon {
              display: block;
              width: 18px;
              height: 18px;
              // vertical-align: middle;
            }
          }

          .@{css-prefix}card-header-content {
            font-size: 14px;
            color: #666666;
          }
        }
      }

      .@{css-prefix}card-body {
        padding: 15px;
      }

      & + & {
        margin-top: 0;
        padding-top: 10px;
        .hairline('top', 1PX, #EEEEEE);
      }
    }
  }

  .@{sectionPrefixCls}-client {
    padding: 0 15px;
    background-color: #FFFFFF;

    & /deep/ .van-cell-group {
      .van-cell:not(:last-child)::after {
        left: 30px;
        right: 0;
        border-color: #EEEEEE;
      }
    }
  }

  .@{css-prefix}commodity-info {

    .@{css-prefix}list {
      &-body {

        .@{css-prefix}list-item {
          &:not(:first-child) {
            .hairline-remove('top');
          }
        }

        .@{css-prefix}list-item {
          padding: 15px 0;

          .@{css-prefix}list-line {
            padding-right: 0;

            .@{css-prefix}list-content {
              padding: 0;
              line-height: 21px;
              font-size: 12px;
              color: #999999;

              .@{css-prefix}commodity-title {
                color: #333333;
                font-weight: bold;
              }

              .@{css-prefix}commodity-price {
                > em {
                  font-size: 15px;
                  font-weight: bold;
                  color: #D8B084;
                }
              }
            }

            @{css-prefix}list-remark {
              padding: 0;
              font-size: 12px;
              color: #999999;
            }

            .@{css-prefix}list-thumb {
              > img {
                display: block;
                width: 120px;
                height: 80px;
              }
            }
          }

          .@{css-prefix}list-title {
            font-size: 15px;
            color: #D2A36D;
            font-weight: bolder;
            display: flex;
            justify-content: center;
            width: 100%;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }
  }

  .@{css-prefix}staff-info {
    &-header {
      padding-top: 10px;
      line-height: 24px;
      font-size: 14px;
      color: #999999;
    }

    &-body {
      padding-top: 5px;
      padding-bottom: 15px;

      .@{css-prefix}list {
        &-body {

          .@{css-prefix}list-item {
            &:not(:first-child) {
              .hairline-remove('top');
            }
          }

          .@{css-prefix}list-item {
            min-height: 28px;
            padding-left: 0;

            .@{css-prefix}list-line {
              padding-right: 0;

              .@{css-prefix}list-content,
              .@{css-prefix}list-label {
                padding: 0;
                font-size: 12px;
                color: #999999;
              }

              .@{css-prefix}list-label {
                &.@{css-prefix}list-label-5 {
                  width: 60px;
                }
              }
            }
          }
        }
      }
    }
  }

  .@{css-prefix}rent {

    &-content {
      background-color: #ffffff;

      .@{css-prefix}rent-content-item {
        min-height: 46px;
        padding-left: 0;

        .@{css-prefix}list-line {
          padding: 7px 0;

          .@{css-prefix}list-content,
          .@{css-prefix}list-extra {
            text-overflow: inherit;
            white-space: normal;
          }

          .@{css-prefix}list-extra {
            flex-basis: 50%;
          }

          .@{css-prefix}list-area {
            flex-basis: 82%;
          }
        }
      }

      &-title {
        font-size: 12px;
        color: #999999;
      }

      &-value {
        line-height: 20px;
        font-size: 12px;
        color: #999999;
      }
    }

    & /deep/ .van-cell {
      padding: 0;

      .van-field__control {
        color: #333333;
        font-size: 15px;
      }

      .van-cell__title {
        color: #333;
        font-size: 14px;
      }

      .van-cell__value {
        color: #999;
        font-size: 14px;
      }

      .van-cell__label {
        margin-top: 5px;
        font-size: 14px;
        line-height: 24px;
      }

      .van-field__word-limit {
        font-size: 15px;
        color: #b9b9b9;

        .van-field__word-num {
          color: #333333;
        }
      }
    }
  }

  // 房型列表
  .@{css-prefix}house-list {
    max-height: 200px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    & /deep/ .van-cell-group {
      padding: 0;

      &.van-hairline--top-bottom,
      &.van-hairline-unset--top-bottom {
        &::after {
          display: none;
        }
      }

      .van-cell {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 40px;
        padding: 0;

        .van-cell__title {
          font-size: 13px;
          color: #333333;
        }

        &::after {
          display: none;
        }
      }
    }

    &-thumb {
      margin-right: 10px;

      > .svg-icon {
        display: block;
        width: 17px;
        height: 17px;
      }
    }

    &-extra {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      min-height: 48px;
      padding: 0 20px 0 15px;
      font-size: 13px;
      color: #c3a475;

      .hairline('left', 1px, #E6E6E6);

      &::before {
        height: auto !important;
        top: 12px !important;
        bottom: 12px !important;
      }

      .svg-icon {
        display: block;
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }
    }
  }

  .@{css-prefix}sign-contract-btns {
    width: 100%;
    padding: 44px;

    .@{css-prefix}btn {
      display: block;
      height: 40px;
      padding: 0 15px;
      line-height: 40px;
      font-size: 15px;
      border-radius: 40px;
      color: #FFFFFF;

      &-primary {
        background-color: #D8B084;
        border-color: #D1A36F;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
      }

      &-ghost {
        background-color: transparent;
        border-color: #D19C70;
        color: #D1A36F;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
      }

      & + .@{css-prefix}btn {
        margin-top: 20px;
      }
    }
  }

  .cyui-describe {
    font-size: 10px;
    font-weight: bold;
    color: red;
  }

  .no-data {
    position: relative;
    left: 0;
    top: 0;
    padding-bottom: 20px;
    font-size: 14px;
    transform: none;
  }

  /deep/ .van-picker__confirm {
    color: #D1A36F;
  }

  /deep/ .van-dialog__header {
    padding: 26px 24px 0;
    color: #646566;
  }

  /deep/ .van-dialog__content {
    .my-textarea {
      .van-cell__value {
        border: 1px solid #ccc !important;

        .van-field__word-limit {
          padding-right: 10px;
        }
      }
    }
  }

  /deep/ .van-dialog__confirm {
    background: #D8B084;
  }

  .copy-text {
    padding: 20px;
    text-align: center;
    color: #999;
  }
</style>
